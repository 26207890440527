import { Context, FormElementState, FormState } from "../../reducers/formSlice";
import { Backend } from "../../services/api/backend";
import { ASSET_NAME } from "../ui/asset";
import {
  MappedEnum,
  getOptions,
  getOriginalOption,
  variantsMap,
} from "../ui/variant";
import {
  b64toBlob,
  constructFieldFromType,
  encodeArrayBuffer,
  entityFields,
  entityUniqueIdentifier,
  fieldCardinalityMany,
  fieldCardinalityOpt,
  fieldName,
  fieldRelation,
  fieldType,
  getEntityUnique,
  getEnumUnique,
  getMapUnique,
  getPrimitive,
  getRecordUnique,
  getTypeData,
  headersBase64,
  isEnum,
  isListSet,
  isMap,
  isPrimitive,
  isRecord,
} from "./Base";
import {
  Entity,
  Field,
  Newtype,
  RecordType,
  Schema,
} from "./Schema";
import BigNumber from "bignumber.js";

export interface ConstructedObj {
  copyForm: FormElementState[];
  formObject: any;
  refreshAssets: boolean;
}
export const constructObject = async (
  schema: Schema,
  entity: Entity,
  formElements: FormElementState[],
  context: Context["context"],
  cdnId: string
): Promise<ConstructedObj> => {
  const copyForm: any = { ...formElements };
  let refreshAssets = false;
  console.log(entity);
  console.log(copyForm);
  let constructObj: any = constructData(
    schema,
    entity,
    formElements,
    "",
    context
  );
  console.log(constructObj);
  const assetField = entityFields(entity).find(
    (f: Field) => fieldName(f) === ASSET_NAME
  );
  console.log(assetField);
  if (assetField) {
    if (!cdnId) {
      throw new Error("CDN canister id not passed...");
    }
    refreshAssets = true;
    const assetData = formElements.find((fd: any) =>
      fd.key.includes(fieldName(assetField))
    );
    if (!assetData) {
      throw new Error("asset form not found...");
    }
    let assetId = 0;
    let batchId = "";
    if (assetData.value === "") {
      const fileInfo = assetData.pre.fileInfo;
      // console.log(assetData.pre.fileInfo);
      // console.log(fileInfo);
      let iid = assetData.pre.fileInfo.id[0];
      console.log(iid);
      if (typeof iid === "string" && /^\d+n$/.test(iid)) {
        iid = BigInt(iid.substr(0, iid.length - 1)).toString();
      }
      const blob = b64toBlob(
        headersBase64(fileInfo.preview),
        assetData.pre.fileInfo.type
      );
      const numBlob = encodeArrayBuffer(await blob.arrayBuffer());
      const bucket = await Backend.loadActorByName("cdn_bucket", cdnId);
      const { batch_id } = await bucket.init_upload({
        ...assetData.pre.fileInfo,
        internal_id: iid,
        preview: [numBlob],
      });
      batchId = Number(batch_id).toString();

      assetId = iid;
      console.log(assetId);
    } else {
      assetId = assetData.value;
    }
    console.log(assetId);
    console.log(typeof assetId);
    constructObj[fieldName(assetField)] = assetId;
    Object.values(copyForm).map((obj: any, key: number) =>
      obj.key === ASSET_NAME
        ? (copyForm[key] = { ...obj, value: assetId, batchId: batchId })
        : obj
    );
  }
  return {
    formObject: constructObj,
    copyForm: copyForm,
    refreshAssets: refreshAssets,
  };
};

export const constructData = (
  schema: Schema,
  entity: Entity | RecordType,
  formElements: FormElementState[],
  extras: string,
  context: Context["context"]
) => {
  const fields: Field[] = entityFields(entity as Entity);
  const constructObj: any = {};
  for (const field of fields) {
    const fieldNameStr = fieldName(field);
    if (
      fieldNameStr === "created" ||
      fieldNameStr === "modified" ||
      (fieldNameStr === "id" && context === "Create")
    ) {
      continue;
    }
    constructObj[fieldNameStr] = constructField(
      schema,
      field,
      formElements,
      extras,
      context
    );
  }
  return constructObj;
};

export const constructField = (
  schema: Schema,
  field: Field,
  formElements: FormElementState[],
  extras: string,
  context: Context["context"]
) => {
  const fieldNameStr = fieldName(field);
  // skip all assets that needs to be uploaded and don't have an id
  if (fieldNameStr === ASSET_NAME) {
    return;
  }

  if (fieldRelation(field)) {
    let formData = formElements.filter((fd: FormElementState) => {
      return fd.key === `${extras}${fieldNameStr}`;
    });
    console.log(field);
    console.log(formData);
    return formData[0].value;
  }
  if (fieldCardinalityMany(field)) {
    let formData = formElements.filter((fe: FormElementState) => {
      return fe.key.startsWith(`${extras}${fieldNameStr}-array_key_`);
    });
    // console.log(formData);
    // console.log(formElements);
    // console.log(`${extras}${fieldNameStr}-`);
    if (formData.length === 1 && formData[0].value.length === 0) {
      return formData[0].value;
    }
    return manyField(schema, field, formData, extras, context);
  }
  if (fieldNameStr === "colour") {
    let formData = formElements.filter((fd: FormElementState) => {
      return fd.key === `${extras}${fieldNameStr}`;
    });
    return formData[0].value;
  }
  const ident = field.value.item.is;
  if (!ident) {
    throw new Error("field value item is not found...");
  }
  const typeDef = getTypeData(schema, ident);
  
  console.log(typeDef);
  if (isRecord(typeDef)) {
    let formData = formElements.filter((fd: FormElementState) => {
      return fd.key.startsWith(`${extras}${fieldNameStr}-object`);
    });
    if (formData.length === 0 && fieldCardinalityOpt(field)) {
      return null;
    }
    // console.log(parentType);
    // console.log(fieldType(field));
    // console.log(getRecordByName(schema, (fieldType(field) as string)));
    const mapped = constructData(
      schema,
      typeDef as RecordType,
      formData,
      `${extras}${fieldNameStr}-object-`,
      context
    );
    return mapped;
  }
  if (isEnum(typeDef)) {
    const dataOut = enumField(schema, field, formElements, extras, context);
    return dataOut;
  }
  if (isListSet(typeDef)) {
    let formData = formElements.filter((fd: FormElementState) => {
      return fd.key.startsWith(`${extras}${fieldNameStr}-array_key_`);
    });
    if (formData.length === 1 && formData[0].value.length === 0) {
      return formData[0].value;
    }
    return listSetField(schema, field, formData, extras, context);
  }
  if (isMap(typeDef)) {
    let formData = formElements.filter((fd: FormElementState) => {
      return fd.key.startsWith(`${extras}${fieldNameStr}-array_key_`);
    });
    if (formData.length === 1 && formData[0].value.length === 0) {
      return formData[0].value;
    }
    return mapField(schema, field, formData, extras, context);
  }
  // const parentType = getPrimitive(schema, field);
  let formData: FormElementState[] = formElements.filter(
    (fd: FormElementState) => {
      return fd.key === `${extras}${fieldNameStr}`;
    }
  );
  if (formData.length > 1) {
    throw new Error("only 1 value should get here...");
  }
  console.log(field);
  console.log(formData);
  // if (fieldNameStr === 'id') {
  //   return '';
  // }
  let value = formData[0].value;
  return value;
};

const manyField = (
  schema: Schema,
  field: Field,
  formElements: FormElementState[],
  extras: string,
  context: Context["context"]
): any => {
  const fieldNameStr = fieldName(field);
  const arrayFormValues = [];
  for (let key of extractKeys(field, formElements, extras)) {
    const ident = field.value.item.is;
    if (!ident) {
      throw new Error("field value item is not found...");
    }
    const typeDef = getTypeData(schema, ident);
    if (isRecord(typeDef)) {
      const fieldExtra = `${extras}${fieldNameStr}-array_key_${key}-${fieldNameStr}-object-`;
      const singleFormMapValues = formElements.filter((fd: FormElementState) =>
        fd.key.includes(fieldExtra)
      );
      const recursive = constructData(
        schema,
        getRecordUnique(schema, fieldType(field) as string),
        singleFormMapValues,
        fieldExtra,
        context
      );
      arrayFormValues.push(recursive);
      continue;
    }
    if (isEnum(typeDef)) {
      const fieldExtra = `${extras}${fieldNameStr}-array_key_${key}-`;
      const singleFormMapValues = formElements.filter((fd: FormElementState) =>
        fd.key.includes(fieldExtra)
      );
      const enumValues = enumField(
        schema,
        field,
        singleFormMapValues,
        fieldExtra,
        context
      );
      arrayFormValues.push(enumValues);
      continue;
    }
    if (isMap(typeDef)) {
      const fieldExtra = `${extras}${fieldNameStr}-array_key_${key}-`;
      let copyObj: Field = { ...field };
      copyObj["value"] = { ...field.value, cardinality: "One" };
      const singleFormMapValues = formElements.filter((fd: FormElementState) =>
        fd.key.startsWith(fieldExtra)
      );
      console.log(fieldExtra);
      console.log(singleFormMapValues);
      if (
        singleFormMapValues.length === 1 &&
        Object.values(singleFormMapValues[0].value).length === 0
      ) {
        arrayFormValues.push(singleFormMapValues[0].value);
        continue;
      }
      arrayFormValues.push(
        mapField(schema, copyObj, singleFormMapValues, fieldExtra, context)
      );
      continue;
    }
    if (isListSet(typeDef)) {
      const fieldExtra = `${extras}${fieldNameStr}-array_key_${key}-`;
      let copyObj: Field = { ...field };
      copyObj["value"] = { ...field.value, cardinality: "One" };
      const singleFormMapValues = formElements.filter((fd: FormElementState) =>
        fd.key.startsWith(fieldExtra)
      );
      if (
        singleFormMapValues.length === 1 &&
        singleFormMapValues[0].value.length === 0
      ) {
        arrayFormValues.push(singleFormMapValues[0].value);
        continue;
      }
      arrayFormValues.push(
        listSetField(schema, copyObj, singleFormMapValues, fieldExtra, context)
      );
      continue;
    }
    if (typeDef.type === 'Newtype' || isPrimitive(typeDef) || fieldRelation(field)) {
      const parentType = getPrimitive(schema, field);
      const fieldExtra = `${extras}${fieldNameStr}-array_key_${key}-`;
      const singleFormMapValues = formElements.filter((fd: FormElementState) =>
        fd.key.includes(fieldExtra)
      );
      arrayFormValues.push(singleFormMapValues[0].value);
      continue;
    }
  }

  return arrayFormValues;
};

export const extractKeys = (
  field: Field,
  formElements: FormElementState[],
  extras: string
): number[] => {
  const manyKeys: number[] = [];
  // console.log(formElements);
  // console.log(`${extras}${fieldName(field)}-array_key_`);
  for (let dataInList of formElements) {
    let key = dataInList.key.split(
      `${extras}${fieldName(field)}-array_key_`
    )[1];
    let keyArr = key.split("-")[0];
    if (keyArr !== undefined && !manyKeys.includes(Number(keyArr))) {
      manyKeys.push(Number(keyArr));
    }
  }

  return manyKeys;
};

const enumField = (
  schema: Schema,
  field: Field,
  formElements: FormElementState[],
  extras: string,
  context: Context["context"]
) => {
  const fieldNameStr = fieldName(field);
  const enumType = getEnumUnique(schema, fieldType(field) as string);
  console.log(fieldType(field));
  console.log(enumType);
  const originalOptions = variantsMap(enumType.variants);
  const options: MappedEnum[] = getOptions(enumType);
  const mappedOptions = [...options].map(
    (option: any) => `${extras}${fieldNameStr}-${option.label}`
  );
  let values: FormElementState[] = formElements.filter(
    (fd: FormElementState) => {
      return mappedOptions.some((option) => fd.key.startsWith(option));
    }
  );

  // NULL type checkbox
  if (values.length === 0 && fieldCardinalityOpt(field)) {
    return null;
  }

  const optionType = values[0].key.split(`${extras}${fieldNameStr}-`)[1];
  // console.log(optionType);
  const optionTypeName = optionType.split("-")[0];
  // console.log(optionTypeName);
  const selectedOption = options.find(
    (option: any) => option.label === optionTypeName
  );
  if (!selectedOption) {
    throw new Error("selected option not found...");
  }
  console.log(selectedOption);
  console.log(field);
  console.log(fieldRelation(field));
  if (selectedOption.type === null) {
    let value = selectedOption.label;
    return value;
  }
  const optionTypeDef = getEntityUnique(schema, selectedOption.type);
  console.log(optionTypeDef);
  if (
    optionTypeDef &&
    (optionTypeDef.type === "Primitive" || optionTypeDef.type === "Entity")
  ) {
    const finalValue = { [selectedOption.label]: values[0].value };
    return finalValue;
  }
  // const variantTypeDef = originalOptions.find((e: any) => e.name === selectedOption.label);
  if (optionTypeDef && optionTypeDef.type === "Record") {
    const mapped = constructData(
      schema,
      optionTypeDef as RecordType,
      values,
      `${extras}${fieldNameStr}-${selectedOption.label}-object-`,
      context
    );
    return { [selectedOption.label]: mapped };
  }

  const mapped: any = constructField(
    schema,
    getOriginalOption(originalOptions, selectedOption),
    values,
    `${extras}${fieldNameStr}-`,
    context
  );
  return { [selectedOption.label]: mapped };
  // return fieldCardinalityOpt(field) ? [{ [selectedOption.label]: mapped }] : { [selectedOption.label]: mapped };
};

const mapField = (
  schema: Schema,
  field: Field,
  formElements: FormElementState[],
  extras: string,
  context: Context["context"]
): any => {
  // console.log(dataIn);
  // console.log(extras);
  const fieldNameStr = fieldName(field);
  const fieldTypeName = fieldType(field);
  if (!fieldTypeName) {
    throw new Error("field type not found...");
  }
  const mapType = getMapUnique(schema, fieldTypeName);

  const arrResult: any = {};
  for (let key of extractKeys(field, formElements, extras)) {
    const fieldExtra = `${extras}${fieldNameStr}-array_key_${key}-`;
    const singleFormMapValues = formElements.filter((fd: FormElementState) =>
      fd.key.includes(fieldExtra)
    );
    const mapKey = singleFormMapValues.filter(
      (elem: FormElementState) => elem.key === fieldExtra + "Key"
    );
    const mapValues = singleFormMapValues.filter((elem: FormElementState) =>
      elem.key.startsWith(fieldExtra + "Value")
    );
    const keyResult = constructField(
      schema,
      constructFieldFromType(mapType.key, "One", "Key"),
      mapKey,
      fieldExtra,
      context
    );
    console.log(fieldExtra);
    console.log(keyResult);
    console.log(typeof keyResult);
    const valueResult = constructField(
      schema,
      constructFieldFromType(
        mapType.value.item,
        mapType.value.cardinality,
        "Value"
      ),
      mapValues,
      fieldExtra,
      context
    );
    arrResult[keyResult] = valueResult;
  }
  return arrResult;
};

const listSetField = (
  schema: Schema,
  field: Field,
  formElements: FormElementState[],
  extras: string,
  context: Context["context"],
  candid?: boolean
): any => {
  // console.log(dataIn);
  // console.log(extras);
  const fieldNameStr = fieldName(field);
  const fieldTypeName = fieldType(field);
  if (!fieldTypeName) {
    throw new Error("field type not found...");
  }
  const listSetType = getTypeData(schema, fieldTypeName);

  const arrResult = [];
  for (let key of extractKeys(field, formElements, extras)) {
    const fieldExtra = `${extras}${fieldNameStr}-array_key_${key}-`;
    const singleFormMapValues = formElements.filter((fd: FormElementState) =>
      fd.key.includes(fieldExtra)
    );

    const constructedField: Field = constructFieldFromType(
      (listSetType as Newtype).value.item,
      "One",
      entityUniqueIdentifier(listSetType)
    );
    const slResult = constructField(
      schema,
      constructedField,
      singleFormMapValues,
      fieldExtra,
      context
    );
    console.log(slResult);
    arrResult.push(slResult);
  }

  return arrResult;
};

export const flattenDataRecursive = (
  schema: Schema,
  entity: Entity | RecordType,
  data: any,
  extras: string
) => {
  const flatten: any[] = [];
  // console.log(entity);
  for (const field of Object.values(entity.fields.fields)) {
    const result = flattenDataField(schema, field, data, extras);
    Array.isArray(result) ? flatten.push(...result) : flatten.push(result);
  }
  // console.log(flatten);
  return flatten;
};

export const flattenDataField = (
  schema: Schema,
  field: Field,
  data: any,
  extras: string
): any => {
  // console.log(field);
  const fieldNameStr = fieldName(field);
  // console.log(field);
  if (fieldRelation(field)) {
    // console.log(fieldNameStr);
    // console.log(data[fieldNameStr]);
    // console.log(data);
    // console.log(`${extras}${fieldNameStr}`);
    return { key: `${extras}${fieldNameStr}`, value: data[fieldNameStr] };
  }
  // console.log(fieldNameStr);
  // console.log(type);
  if (fieldCardinalityMany(field)) {
    // console.log(fieldNameStr);
    // console.log(data);
    const multiData = data[fieldNameStr];
    // console.log(multiData);
    if (multiData && Array.isArray(multiData) && multiData.length === 0) {
      return { key: `${extras}${fieldNameStr}`, value: [] };
    }
    let count = 0;
    // console.log(multiData);
    const flattenMulti = [];
    for (const fieldData of multiData) {
      const fieldExtra = `${extras}${fieldNameStr}-array_key_${count}-`;
      // console.log(fieldData);
      const updatedField = {
        ...field,
        value: { ...field.value, cardinality: "One" },
      };
      const result = flattenDataField(
        schema,
        updatedField,
        { [fieldNameStr]: fieldData },
        fieldExtra
      );
      // console.log(result);
      // console.log(Array.isArray(result));
      Array.isArray(result)
        ? flattenMulti.push(...result)
        : flattenMulti.push(result);
      count++;
    }
    // console.log(flattenMulti);
    return flattenMulti;
  }

  const fieldData = data[fieldNameStr];
  // console.log(fieldData);
  if (
    fieldCardinalityOpt(field) &&
    (fieldData === null || (Array.isArray(fieldData) && fieldData.length === 0))
  ) {
    return { key: `${extras}${fieldNameStr}`, value: null };
  }
  const ident = field.value.item.is;
  if (!ident) {
    throw new Error("field value item is not found...");
  }
  const parentType = getTypeData(schema, ident);
  if (isRecord(parentType)) {
    // console.log(getRecordUnique(schema, (fieldType(field) as string)));
    return flattenDataRecursive(
      schema,
      parentType as RecordType,
      fieldData,
      `${extras}${fieldNameStr}-object-`
    );
  }
  if (isEnum(parentType)) {
    // console.log(fieldData);
    // non value type or simple value
    if (typeof fieldData !== "object") {
      // console.log(`${extras}${fieldNameStr}`);
      return { key: `${extras}${fieldNameStr}-${fieldData}`, value: fieldData };
    }
    const enumType = getEnumUnique(schema, fieldType(field) as string);
    const options: MappedEnum[] = getOptions(enumType);
    // const originalOptions = variantsMap(enumType.variants);

    const selectedOption = options.find(
      (option: any) => option.label === Object.keys(fieldData)[0]
    );
    if (!selectedOption) {
      throw new Error("selected option not found...");
    }
    // console.log(selectedOption);
    if (selectedOption.type === null) {
      return {
        key: `${extras}${fieldNameStr}-${selectedOption.label}`,
        value: fieldData,
      };
    }
    const optionTypeDef = getEntityUnique(schema, selectedOption.type);

    if (optionTypeDef && optionTypeDef.type === "Primitive") {
      return {
        key: `${extras}${fieldNameStr}-${selectedOption.label}`,
        value: fieldData[selectedOption.label],
      };
    }
    // const enumType1 = variants.find((e: any) => e.name === selectedOption.label);
    if (optionTypeDef && optionTypeDef.type === "Record") {
      return flattenDataRecursive(
        schema,
        optionTypeDef as RecordType,
        fieldData[selectedOption.label],
        `${extras}${fieldNameStr}-${selectedOption.label}-object-`
      );
    }

    const constructedField = constructFieldFromType(
      {
        is: selectedOption.type,
      } as Newtype['value']["item"],
      "One",
      selectedOption.label
    );
    const result = flattenDataField(
      schema,
      constructedField,
      fieldData,
      `${extras}${fieldNameStr}-`
    );
    return result;
  }
  if (isMap(parentType)) {
    // console.log(field);
    // console.log(fieldData);
    // console.log(fieldData.size);
    const flattenMap = [];
    const fieldTypeName = fieldType(field);
    if (!fieldTypeName) {
      throw new Error("field type not found...");
    }
    if (fieldData instanceof Map) {
      if (fieldData.size === 0) {
        return { key: `${extras}${fieldNameStr}`, value: {} };
      }
      const mapType = getMapUnique(schema, fieldTypeName);
      const keyDef = constructFieldFromType(mapType.key, "One", "Key");
      const valueDef = constructFieldFromType(
        mapType.value.item,
        mapType.value.cardinality,
        "Value"
      );
      let count = 0;
      // @ts-ignore
      for (const [key, value] of fieldData.entries()) {
        // console.log(key);
        // console.log(value);
        const fieldExtra = `${extras}${fieldNameStr}-array_key_${count}-`;
        // console.log(fieldData);
        const keyResult = flattenDataField(
          schema,
          keyDef,
          { [fieldName(keyDef)]: key },
          fieldExtra
        );
        const valueResult = flattenDataField(
          schema,
          valueDef,
          { [fieldName(valueDef)]: value },
          fieldExtra
        );
        // console.log(fieldName(keyDef));
        // console.log(keyResult);
        // console.log(typeof keyResult);
        // // console.log(valueResult);
        flattenMap.push(keyResult);
        Array.isArray(valueResult)
          ? flattenMap.push(...valueResult)
          : flattenMap.push(valueResult);
        count++;
      }
    } else {
      if (Object.keys(fieldData).length === 0) {
        return { key: `${extras}${fieldNameStr}`, value: {} };
      }
      const mapType = getMapUnique(schema, fieldTypeName);
      const keyDef = constructFieldFromType(mapType.key, "One", "Key");
      const valueDef = constructFieldFromType(
        mapType.value.item,
        mapType.value.cardinality,
        "Value"
      );
      // console.log(keyDef);
      // console.log(fieldData);
      let count = 0;

      for (const mapData of Object.keys(fieldData)) {
        const keyData: any = mapData;
        const valueData = fieldData[keyData];
        const fieldExtra = `${extras}${fieldNameStr}-array_key_${count}-`;
        // console.log(fieldData);
        const keyResult = flattenDataField(
          schema,
          keyDef,
          { [fieldName(keyDef)]: keyData },
          fieldExtra
        );
        const valueResult = flattenDataField(
          schema,
          valueDef,
          { [fieldName(valueDef)]: valueData },
          fieldExtra
        );
        console.log(fieldName(keyDef));
        console.log(keyResult);
        console.log(typeof keyResult);
        // console.log(valueResult);
        flattenMap.push(keyResult);
        Array.isArray(valueResult)
          ? flattenMap.push(...valueResult)
          : flattenMap.push(valueResult);
        count++;
      }
    }
    return flattenMap;
  }
  if (isListSet(parentType)) {
    const fieldNameStr = fieldName(field);
    // console.log(fieldNameStr);
    // console.log(fieldData);
    if (Array.isArray(fieldData) && fieldData.length === 0) {
      return { key: `${extras}${fieldNameStr}`, value: [] };
    }

    const fieldTypeName = fieldType(field);
    if (!fieldTypeName) {
      throw new Error("field type not found...");
    }
    const constructedField: Field = constructFieldFromType(
      (parentType as Newtype).value.item,
      "One",
      entityUniqueIdentifier(parentType)
    );
    // console.log(constructedField);
    let count = 0;
    const flattenMap: any = [];
    for (const mapData of fieldData) {
      // console.log(mapData);
      const fieldExtra = `${extras}${fieldNameStr}-array_key_${count}-`;
      // console.log(fieldData);
      const result = flattenDataField(
        schema,
        constructedField,
        { [fieldName(constructedField)]: mapData },
        fieldExtra
      );
      Array.isArray(result)
        ? flattenMap.push(...result)
        : flattenMap.push(result);
      count++;
    }

    return flattenMap;
  }
  let finalData = data[fieldNameStr];
  if (typeof finalData === "bigint") {
    finalData = finalData.toString() + "n";
  }
  if (BigNumber.isBigNumber(finalData)) {
    finalData = finalData.toFixed().toString() + "n";
  }
  // console.log(`${extras}${fieldNameStr}`);
  // flatten.push({ key: `${extras}${fieldNameStr}`, value: wrapNullable(finalData, field) });
  return { key: `${extras}${fieldNameStr}`, value: finalData };
};

export const dataStart = (values: FormElementState[], inputTarget: string) => {
  return Array.isArray(values) && values.filter((e: any) => e.key.startsWith(inputTarget));
};

export const dataExact = (values: FormElementState[], inputTarget: string) => {
  return Array.isArray(values) && values.find((e: any) => e.key === inputTarget);
};
