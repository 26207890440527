import { FC, useContext, useEffect, useLayoutEffect, useState } from "react";
import { EnvironmentContext } from "../../..";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RECORDS_PATH, loadState } from "../../reducers/schemaSlice";
import { Entity } from "./Schema";
import {
  CONTEXT_CREATE,
  FormElements,
  FormHeader,
  entityName,
  getBucketCanister,
  getEntityUnique,
} from "./Base";
import { useAppDispatch, useAppSelector } from "../../services/hooks";
import {
  FormElementState,
  clearForm,
  formState,
  setFormName,
} from "../../reducers/formSlice";
import { Backend } from "../../services/api/backend";
import LoadingSpinner from "../ui/spinner";
import { CreateArgs, useCreateMutation } from "../../reducers/crud/createSlice";
import { constructObject } from "./DataProcessor";
import {
  CanisterState,
  useCdnCanistersQuery,
} from "../../reducers/canisterSlice";
import { postModify } from "../ui/asset";
import { getCanisterIdByName, useActiveCanisters } from "../layout";

const Create: FC = () => {
  let { model } = useParams();
  let { pathname: url } = useLocation();
  const { environment } = useContext(EnvironmentContext);
  const schema = loadState();
  // for (let entity of Object.values(schema)) {
  //   if (entity.type === "Entity") {
  //     // console.log((entity as any).fields);
  //     // @ts-ignore
  //     for (let field of Object.values(entity.fields.fields)) {
  //       // console.log(field);
  //       const fieldPath = fieldTypeNew(field as any);
  //       // console.log(fieldPath);
  //       const typeDef: SchemaTypes = getTypeDataNew(schema, fieldPath);
  //       if (typeDef.type === "Selector") {
  //         console.log(entityName(entity as any));
  //         break;
  //       }
  //     }
  //   }
  //   };
  const entity: Entity | undefined = getEntityUnique(schema, model as string);
  if (!entity) {
    return <h3>Entity {model} not found!</h3>;
  }
  // console.log(entity);
  
  const canisterId = getCanisterIdByName(
    entity,
    environment,
    useActiveCanisters().main
  );
  if (canisterId === "") {
    return <></>;
  }

  let pathParts = url.split("/");
  const pkArray =
    pathParts.length > 4
      ? pathParts
        .slice(4)
        .map((part) => decodeURIComponent(part))
        .filter((part) => part !== "")
      : [];
  // console.log(pkArray);

  const [createTrigger, { data, error }] = useCreateMutation();
  const { data: cdnCanisters, isLoading: canistersLoading } =
    useCdnCanistersQuery(environment);
  const [loading, setLoading] = useState(false);
  const { form, context } = useAppSelector(formState);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const { data : canisterData } = useAppSelector(canisters);
  // console.log(form);
  useEffect(() => {
    dispatch(
      setFormName({ name: entityName(entity), context: CONTEXT_CREATE })
    );
  }, []);

  useLayoutEffect(() => {
    return () => {
      console.log("leaving screen...");
      dispatch(clearForm());
    };
  }, []);

  const handleSubmit = async (
    e: React.MouseEvent<HTMLElement>,
    navigate: any
  ): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    if (!cdnCanisters) {
      setLoading(false);
      throw new Error(
        "No CDN canister loaded... make sure to clean the local storage."
      );
    }
    const bucketId = getBucketCanister(cdnCanisters);
    // console.log(entity);
    const obj = await constructObject(
      schema,
      entity,
      form,
      CONTEXT_CREATE,
      bucketId
    );
    console.log(obj);
    let encoded = JSON.stringify(obj.formObject, (key, value) =>
      typeof value === "bigint" ? value.toString() + "n" : value
    );
    const entityData: CreateArgs = {
      objectData: encoded,
      e: entity,
      cid: canisterId,
      pks: pkArray,
    };
    try {
      const response = await createTrigger(entityData).unwrap();
      if (response.error && response.error.message !== "") {
        setLoading(false);
        return response.error.message;
      }
      await postModify(Object.values(obj.copyForm), bucketId);
      setLoading(false);
      navigate(`/${RECORDS_PATH}/${location.pathname.split("/")[2]}`);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const hasErrors =
    form.filter((field: FormElementState) => field.error.length > 0).length > 0;
  const overlayClass = loading === true ? "display-flex" : "d-none";
  // console.log(error);
  return (
    <div className="mt-3 mb-5">
      <div className="row">
        <div className="col-12">
          <div className="card ">
            <div className="card-header">
              <FormHeader entity={entity} url={url} context={CONTEXT_CREATE} />
            </div>
            {/* {emptyOrNull(getBucketCanister(canisterData)) && 
        <div key="danger" className="alert alert-danger">
          No CDN canister loaded... make sure to clean the local storage.
        </div>
      } */}
            <div className="card-body">
              {error &&
                (typeof error === "object" ? (
                  <div className="alert alert-danger">
                    {(error as any).error}
                  </div>
                ) : (
                  JSON.parse(error as string).map((e: any, index: number) => (
                    <div key={index} className="alert alert-danger">
                      {e.key} -- {e.value}
                    </div>
                  ))
                ))}
              <div id="overlay" className={overlayClass}>
                <div className="w-100 d-flex justify-content-center align-items-center">
                  <LoadingSpinner altText="loading" theme="lg" />
                </div>
              </div>
              <FormElements entity={entity} data={[]} />
            </div>
            <div className="card-footer mb-5">
              <button
                disabled={hasErrors}
                onClick={(e) => handleSubmit(e, navigate)}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
