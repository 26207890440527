import { memo, useContext, useState } from "react";
import { Field, Newtype } from "../crud/Schema";
import { extractNonNewtype, fieldName, getStateNameExtra } from "../crud/Base";
import { loadState } from "../../reducers/schemaSlice";
import FormElement from "./form.element";
import ColourElement from "./colourPicker";
import { dataStart } from "../crud/DataProcessor";
import { EnvironmentContext } from "../../..";

export interface NewTypeElement {
    field: Field;
    type: Newtype;
    width: number;
    extras: string;
    data: any;
};


const NewTypeElement: React.FC<NewTypeElement> = (props: NewTypeElement): JSX.Element => {
    const schema = loadState();
    const { field, width, extras, data, type } = props;
    const childType = extractNonNewtype(schema, type);
    // console.log(type);
    // console.log(data);
    // console.log(childType);
    // console.log(field);
    let copyObj: Field = { ...field };
    copyObj['value'] = { item: {...copyObj.value.item, is: childType.id }, cardinality: 'One' };

    let renderedElement: JSX.Element;
    if ((type.id.includes('RgbHex') || type.id.includes('RgbaHex'))) {
        renderedElement = (
            <ColourElement
                hex={false}
                field={field}
                width={width}
                data={dataStart(data, getStateNameExtra(field, extras))}
                alpha={type.id.includes('RgbaHex')}
                extras={extras}
            />
        );
    } else {
        renderedElement = <FormElement
            field={copyObj}
            width={width}
            extras={extras}
            data={data}
        />;
    }
    return (
        <div className="row g-1">
            <div className={`col-md-${width}`}>
                {renderedElement}
            </div>
        </div>

    )
};

export default memo(NewTypeElement);
