
import Select from 'react-select';
import logo from '../../../assets/img/dragginz-logo.jpg';
import { RECORDS_PATH, loadState } from '../../reducers/schemaSlice';
import { entityName, entityUniqueIdentifier, getVisibleEntities } from '../crud/Base';
import { Entity } from '../crud/Schema';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createAuthClient } from '../../services/api/backend';
import { crudListApi } from '../../reducers/crud/listSlice';
import { useAppDispatch } from '../../services/hooks';
import { EnvironmentContext } from '../../..';
const Navbar: React.FC = () => {
  const [isSearch, setIsSearch] = useState(false);
  const [value, setValue] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);
  let navigate = useNavigate();
  const { environment } = useContext(EnvironmentContext);
  const schema = loadState();
  const dispatch = useAppDispatch();

  const entities = getVisibleEntities(schema);
  // console.log(entities);
  
  const handleLogout = async () => {
    const authClient = await createAuthClient();
    await authClient.logout();
    localStorage.removeItem('princiapl');
    localStorage.removeItem('permissions');
    localStorage.removeItem('ic-identity');
    localStorage.removeItem('ic-delegation');
    localStorage.removeItem('identity-created');
    localStorage.removeItem('canisters');
    localStorage.removeItem('username');
    window.location.reload;
}
  const searchMatch = (query: any, target: any) => {
    const words = query.toLowerCase().split(/\s+/);
    return words.every((word: any) => target.toLowerCase().includes(word));
  }

  const handleDocumentClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest('#searchNav')) {
      setShowOptions(false);
      setValue('');
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'Escape') {
        setValue(''); 
        setShowOptions(false);  
        event.preventDefault();
      }
    };
  
    document.addEventListener('click', handleDocumentClick);
    document.addEventListener('keydown', handleKeyDown);
  
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    // console.log(inputValue);
    setValue(inputValue);
    if (inputValue === '') {
      return;
    }
    
    const filtered = entities.filter((option) => searchMatch(inputValue, entityUniqueIdentifier(option))).slice(0, 10);
    setFilteredOptions(filtered);
  };

  const handleInputFocus = () => {
    setShowOptions(true);
    setFilteredOptions(entities.slice(0, 10)); // Show 10 options initially
  };

  const handleOptionSelect = async (selectedOption: any) => {
    await dispatch(crudListApi.util.resetApiState());
    setShowOptions(false); // Hide options after selection
    navigate(`/${RECORDS_PATH}/${encodeURIComponent(entityUniqueIdentifier(selectedOption) )}`);
  };
  return (
    <>
    <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
      <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="/">Dragginz Admin  <img id="main-logo" src={logo} alt="" className="logo" width="30px" /></a>
      <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="input-dropdown-container w-100">
        <input
          id="searchNav"
          className="form-control form-control-dark w-100"
          type="text"
          placeholder="Search..."
          aria-label="Search..."
          value={value}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
        {showOptions && (
          <div className="dropdown-menu d-block w-100">
            {filteredOptions.map((option, index) => (
              <a
                key={index}
                className="dropdown-item"
                onClick={() => handleOptionSelect(option)}
              >{entityName(option)} - {entityUniqueIdentifier(option)} </a>
            ))}
          </div>
        )}
      </div>
      <div className="navbar-nav">
        <div className="nav-item text-nowrap">
        <button onClick={handleLogout} className="nav-link px-3">
          <i className="bi bi-box-arrow-right"/> Logout
        </button>
        </div>
      </div>
      
    </header>
    
    </>
  );
};
export default Navbar;

