import { memo, useContext } from "react";
import { loadState } from "../../reducers/schemaSlice";
import {
  ASSET_TYPE, entityName, entityUniqueIdentifier, fieldCardinalityMany, fieldName,
   fieldRelation,fieldType, getEntityByPath, getStateNameExtra,
  getTypeData,
  isEnum,
  isListSet,
  isMap,
  isRecord,
  isTuple
} from "../crud/Base";
import { dataExact, dataStart } from "../crud/DataProcessor";
import {Field, Newtype, PrimitiveType, RecordType, SchemaTypes } from "../crud/Schema";

import InputTypeElement from "./input";
import ManyElement from "./many";
import MapElement from "./map";
import RecordElement from "./record";
import { RelationElement } from "./relation";
import TupleElement from "./tuple";
import VariantElement from "./variant";
import NewTypeElement from "./newtype";
import SelectorElement from "./selector";
import { EnvironmentContext } from "../../..";

export interface ElementProps {
  field: Field;
  extras: string;
  data: any[];
  width?: number;
};

export interface FormTypeProps {
  formType: string;
  parentRef?: SchemaTypes;
  alpha?: boolean;
}

export const formTypeValue = (field: Field): FormTypeProps => {
  const schema = loadState();
  let formType = '';
  let alpha = false;
  let parentRef = undefined;
  const fieldTypeValue = fieldType(field);
  if (fieldTypeValue) {
    const typeDef = getTypeData(schema, fieldTypeValue);
    // console.log(typeDef);
    if (isTuple(typeDef)) {
      formType = 'Tuple';
    }
    if (isMap(typeDef)) {
      formType = 'Map';
      parentRef = typeDef;
    }
    if (isRecord(typeDef)) {
      formType = 'Record';
      // TODO: Fix this once there's a usage
      if (entityName((typeDef as RecordType)) === "Rgb" ||
        entityName((typeDef as RecordType)) === "Rgba"
      ) {
        alpha = entityUniqueIdentifier(typeDef) === "Rgba";
        formType = 'Colour';
      }
      parentRef = typeDef;
    }
    if (isEnum(typeDef)) {
      formType = 'dropdown-embed';
      parentRef = typeDef;
    }
    if (typeDef.type === 'Newtype' && (typeDef.id.includes('RgbHex') || typeDef.id.includes('RgbaHex'))) {
      formType = 'ColourHex';
      alpha = typeDef.id.includes('RgbaHex');
    }
    // if (isEnumValue(typeDef)) {
    //   formType = 'EnumValue';
    //   parentRef = typeDef;
    // }
    if (isListSet(typeDef)) {
      parentRef = typeDef;
      formType = 'ListSet';
    }
    if (typeDef.type === 'Newtype' && (typeDef as Newtype).primitive) {
      formType = (typeDef as Newtype).primitive as string;
    }

    if (typeDef.type === 'Primitive') {
      formType = (typeDef as PrimitiveType).ty;
    }

    if (['id', 'created', 'modified'].includes(fieldName(field))) {
      formType = 'Hidden';
    }
  }
  // @ts-ignore
  if (fieldTypeValue === 'Id') {
    formType = 'Hidden';
  }
  const relationType = fieldRelation(field);
  if (relationType) {
    formType = 'Relation';
    parentRef = getEntityByPath(schema, relationType);
    if (!parentRef) {
      throw new Error(`Relation ${relationType} not found!`);
    }
    formType = entityUniqueIdentifier(parentRef) === ASSET_TYPE ? 'Asset' : formType;
  }
  if (fieldCardinalityMany(field) && formType !== 'Relation') {
    formType = 'Many';
  }

  return { formType, parentRef, alpha };
}

export const FormElement = (props: ElementProps): JSX.Element => {
  const { extras, field, data, width } = props;
  const rowWidth = width ? width : 10;
  // console.log(data);
  const fieldPath = fieldType(field);
  const schema = loadState();
  const typeDef: SchemaTypes = getTypeData(schema, fieldPath);
  // console.log(field);
  // console.log(typeDef);
  if (fieldCardinalityMany(field) && typeDef.type !== 'Entity') {
    return <ManyElement width={rowWidth} field={field} data={dataStart(data, getStateNameExtra(field, extras) + '-array')} extras={extras} />;
  }
  // console.log(dataStart(data, getStateNameExtra(field, extras)));
  switch (typeDef.type) {
    case 'Newtype':
      return <NewTypeElement width={rowWidth} type={typeDef as Newtype} field={field} data={dataStart(data, getStateNameExtra(field, extras))} extras={extras} />;
    case 'Primitive':
      return <InputTypeElement type={typeDef as PrimitiveType} field={field} data={dataExact(data, getStateNameExtra(field, extras))} width={rowWidth} extras={extras} />;
    case 'Entity':
      return <RelationElement data={dataExact(data, getStateNameExtra(field, extras))} field={field} width={rowWidth} extras={extras} />;
    case 'Record':
      return <RecordElement field={field} data={dataStart(data, getStateNameExtra(field, extras) + '-object')} width={rowWidth} extras={extras} />;
    case 'Enum':
      return <VariantElement field={field} data={dataExact(data, getStateNameExtra(field, extras))} width={rowWidth} extras={extras} />;
    case 'Selector':
      return <SelectorElement field={field} data={dataExact(data, getStateNameExtra(field, extras))} width={rowWidth} extras={extras} />;      
    case 'Tuple':
      return <TupleElement field={field} width={rowWidth} data={data} extras={extras} />;
  case 'Map':
  return <MapElement field={field} data={dataStart(data, getStateNameExtra(field, extras) + '-array_key_')} width={rowWidth} extras={extras} ></MapElement>;
  }

  return <div className="alert alert-danger" role="alert">{fieldName(field)} : Unknown form type detected {typeDef.type} </div>
}
// FormElement.whyDidYouRender = true;
export default memo(FormElement);
