import { memo, useContext, useEffect, useState } from "react";
import { Field, GenericMap } from "../crud/Schema";
import { useAppDispatch, useAppSelector } from "../../services/hooks";
import { FormElementState, deleteByKey, filterAndUpdate, formState, setFormValue } from "../../reducers/formSlice";
import { CONTEXT_UPDATE, constructFieldFromType, emptyOrNull, fieldName,
   fieldType,
   getStateNameExtra, 
   getTypeData} from "../crud/Base";
import FormElement from "./form.element";
import { extractKeys } from "../crud/DataProcessor";
import { loadState } from "../../reducers/schemaSlice";
import { EnvironmentContext } from "../../..";

interface MapElement {
  field: Field;
  width: number;
  extras: string;
  data: any;
};

interface MapStruct {
  key: string;
  record: any;
}

const MapElement: React.FC<MapElement> = (props: MapElement): JSX.Element => {
  const [nullValue, setNullValue] = useState(false);

  const [valueMap, setValueMap] = useState(new Map<number, { key: Field, value: Field }>());
  const dispatch = useAppDispatch();
  const { field, width, extras, data } = props;
  const { form, context } = useAppSelector(formState);
  const schema = loadState();
  const parentRef = (getTypeData(schema, fieldType(field)) as GenericMap);
  const constructedKeyField: Field = constructFieldFromType(parentRef.key, 'One', 'Key');
  const constructedValueField: Field = constructFieldFromType(parentRef.value.item, parentRef.value.cardinality, 'Value');


  const mapStruct = { key: constructedKeyField, value: constructedValueField };
  useEffect(() => {
    if (context === CONTEXT_UPDATE && Object.values(data).length > 0) {
      const keys = extractKeys(field, data, extras);
      const newMap = new Map(valueMap);
      for (let i = 0; i < keys.length; i++) {
        newMap.set(i, mapStruct);
      }
      setValueMap(newMap);
    } else {
      const initialObj: FormElementState = {
        key: getStateNameExtra(field, extras),
        value: {},
        error: [],
        extras: getStateNameExtra(field, extras)
      };
      dispatch(setFormValue(initialObj));
    }
  }, []);

  const add = (e: React.MouseEvent): void => {
    e.preventDefault();
    const newMap = new Map(valueMap);
    const newKey = valueMap.size ? Math.max(...Array.from(valueMap.keys())) + 1 : 0; // compute the new key
    newMap.set(newKey, mapStruct);
    // console.log(getStateNameExtra(field, extras));
    dispatch(deleteByKey(getStateNameExtra(field, extras)));
    setValueMap(newMap);
  };

  const remove = (e: React.MouseEvent, i: number, f: any) => {
    e.preventDefault();
    const newMap = new Map(valueMap);
    newMap.delete(i);
    setValueMap(newMap);
    dispatch(filterAndUpdate(f));
    if (Array.from(newMap.values()).filter((e: any) => !emptyOrNull(e)).length === 0) {
      dispatch(setFormValue({
        key: getStateNameExtra(field, extras),
        value: [],
        extras: getStateNameExtra(field, extras),
        error: []
      }));
    }
  }

  return (
    <div className="row g-1">
      <div className="col-md-1">
        <label className="text-break">
          <small><strong>{fieldName(field)}</strong></small>
        </label>
      </div>
      <div className={`col-md-${width}`}>
        {Array.from(valueMap.entries()).map(([key, childField]) => {
          const fieldExtra = `${fieldName(field)}-array${'_key_' + key}-`;
          return (
            <div key={key} className="col-md-12 container border rounded mb-1 mt-1">
              <FormElement
                field={constructedKeyField}
                width={10}
                extras={extras + fieldExtra}
                data={data}
              />
              <FormElement
                field={constructedValueField}
                width={10}
                extras={extras + fieldExtra}
                data={data}
              />
              <div className="col-md-12">
                {valueMap.size > 0 && (
                  <button onClick={(e) => remove(e, key, extras + fieldExtra)} className="btn btn-outline-danger close-button">x</button>
                )}
              </div>
            </div>
          );
        })}
        <div className="col-md-2">
          <div>
            <br />
            <button onClick={(e) => add(e)} className="btn btn-sm btn-primary">
              Add new: {fieldName(field)}
            </button>
          </div>
        </div>
      </div>
    </div>

  )
};

export default memo(MapElement);
