import React, {
  Suspense,
  createContext,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { createAuthClient } from "../services/api/backend";
import AdminLayout from "./layout";
import { userApi } from "../reducers/userSlice";
import { useAppDispatch } from "../services/hooks";
import {
  CanisterState,
  useMainCanistersQuery,
} from "../reducers/canisterSlice";
import { EnvironmentContext } from "../..";
import LoadingSpinner from "./ui/spinner";
import { startTransition } from "react";
import { loadState } from "../reducers/schemaSlice";

const Login = lazy(() => import("./pages/Login"));
const Page404 = lazy(() => import("./pages/Page404"));
const Issues = lazy(() => import("./pages/Issues"));

export const AuthContext = createContext<{
  isAuthenticated: boolean | null;
  asyncAuth: () => Promise<void>;
  mainCids: any[];
}>({
  isAuthenticated: false,
  asyncAuth: async () => { },
  mainCids: [],
});

const PrivateRoute = ({ children, redirectTo }: any) => {
  const { isAuthenticated, asyncAuth } = useContext(AuthContext);
  // console.log(isAuthenticated);
  useEffect(() => {
    if (isAuthenticated === null) {
      asyncAuth();
    }
  }, [isAuthenticated, asyncAuth]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to={redirectTo} />;
};

const AuthRedirect = ({ children, redirectTo }: any) => {
  const { isAuthenticated, asyncAuth } = useContext(AuthContext);
  console.log(isAuthenticated);
  useEffect(() => {
    if (isAuthenticated === null) {
      asyncAuth();
    }
  }, [isAuthenticated, asyncAuth]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Navigate to={redirectTo} /> : children;
};

export const UserCanisterName = "design::canister::User";

const setSessionStorageWithTTL = (key: string, value: any, ttl: number) => {
  const now = Date.now();
  const item = {
    value,
    expiry: now + ttl,
  };
  sessionStorage.setItem(key, JSON.stringify(item));
};

const getSessionStorageWithTTL = (key: string) => {
  const itemStr = sessionStorage.getItem(key);
  if (!itemStr) {
    return null; // Key does not exist
  }
  const item = JSON.parse(itemStr);
  const now = Date.now();

  if (now > item.expiry) {
    sessionStorage.removeItem(key);
    return null;
  }
  return item.value;
};

const App = () => {
  const [authState, setAuthState] = useState<boolean | null>(null);
  const dispatch = useAppDispatch();
  const { environment } = useContext(EnvironmentContext);
  const { isLoading, data: mainCids } = useMainCanistersQuery(environment);
  // console.log(isLoading);
  const schema = loadState();
  const asyncAuth = async () => {
    const storedAuth = getSessionStorageWithTTL("isAuthenticated");
    if (storedAuth === "true") {
      setAuthState(true);
      return;
    }
    const authClient = await createAuthClient();
    // console.log(mainCids);
    authClient.idleManager?._resetTimer();
    const authenticated = await authClient.isAuthenticated();
    console.log(authenticated);

    if (authenticated) {
      if (!mainCids) {
        return;
      }
      const playerHubCid = mainCids.find(
        (cid: CanisterState) =>
          cid.name === UserCanisterName
      );
      if (!playerHubCid) {
        return;
      }
      const resultAction = await dispatch(
        userApi.endpoints.getPrincipalAndRole.initiate({cid: playerHubCid.cid, schema: schema })
      );
      // console.log(resultAction);
      if (resultAction.isSuccess && resultAction.data.permissions === "admin") {
        setAuthState(true);
        // setSessionStorageWithTTL("isAuthenticated", "true", 30 * 60 * 1000); // 30 minutes
        setSessionStorageWithTTL("isAuthenticated", "true", 2 * 60 * 1000); // 2 minutes
      } else {
        setAuthState(false);
      }
    } else {
      setAuthState(false);
    }
  };
  useEffect(() => {
    if (!isLoading && mainCids) {
      const isAlreadyAuthenticated =
        getSessionStorageWithTTL("isAuthenticated") === "true";
      if (!isAlreadyAuthenticated) {
        asyncAuth();
      } else {
        setAuthState(true); // Set state directly if already authenticated according to sessionStorage
      }
    }
  }, [isLoading, mainCids]);
  // console.log(isLoading);
  // console.log(mainCids);
  if (isLoading) {
    return (
      <div className="w-100 d-flex justify-content-center align-items-center">
        <LoadingSpinner altText="loading" theme="lg" />
      </div>
    );
  } else if (!mainCids) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Issues />
      </Suspense>
    );
  }
  // console.log(authState);
  return (
    <AuthContext.Provider
      value={{ isAuthenticated: authState, asyncAuth, mainCids }}
    >
      <Suspense
        fallback={
          <div>
            <LoadingSpinner altText="loading" theme="lg" />
          </div>
        }
      >
        <Routes>
          <Route
            path="/login"
            element={
              <AuthRedirect redirectTo="/dashboard">
                <Login />
              </AuthRedirect>
            }
          />
          <Route
            path="/*"
            element={
              <PrivateRoute redirectTo="/login">
                <AdminLayout />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
      <div className="backdrop" />
    </AuthContext.Provider>
  );
};

export default App;
