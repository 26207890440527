import { configureStore } from '@reduxjs/toolkit'

import {  crudListApi } from '../reducers/crud/listSlice'
import { userApi } from '../reducers/userSlice'
import { cdnApi } from '../reducers/cdnSlice'
import { crudCreateApi } from '../reducers/crud/createSlice'
import { crudDeleteApi } from '../reducers/crud/deleteSlice'
import { crudEditApi } from '../reducers/crud/editSlice'
import { crudGetApi } from '../reducers/crud/getSlice'
import { relationApi } from '../reducers/crud/relationSlice'
import schemaReducer, { loadState, saveState } from '../reducers/schemaSlice'
import { formSlice } from '../reducers/formSlice'
import { canisterApi } from '../reducers/canisterSlice'
import { typeEnv } from '../components/crud/Schema'

const persistedState = {
  schema: {
    data: loadState()
  },
};


export const store = configureStore({
  reducer: {
    schema: schemaReducer,
    user: userApi.reducer,
    cdn: cdnApi.reducer,
    list: crudListApi.reducer,
    create: crudCreateApi.reducer,
    delete: crudDeleteApi.reducer,
    edit: crudEditApi.reducer,
    get: crudGetApi.reducer,
    relation: relationApi.reducer,
    canister: canisterApi.reducer,
    form: formSlice.reducer,
   
    [userApi.reducerPath]: userApi.reducer,
    [cdnApi.reducerPath]: cdnApi.reducer,
    [crudListApi.reducerPath]: crudListApi.reducer,
    [crudCreateApi.reducerPath]: crudCreateApi.reducer,
    [crudDeleteApi.reducerPath]: crudDeleteApi.reducer,
    [crudEditApi.reducerPath]: crudEditApi.reducer,
    [crudGetApi.reducerPath]: crudGetApi.reducer,
    [relationApi.reducerPath]: relationApi.reducer,
    [canisterApi.reducerPath]: canisterApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      userApi.middleware,
      cdnApi.middleware,
      crudListApi.middleware,
      crudCreateApi.middleware,
      crudDeleteApi.middleware,
      crudEditApi.middleware,
      crudGetApi.middleware,
      relationApi.middleware,
      canisterApi.middleware
    ),
  preloadedState: persistedState,
});

store.subscribe(() => {
  saveState(store.getState().schema.data);
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>