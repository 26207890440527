export const idlFactory = ({ IDL }) => {
  const AppMode = IDL.Variant({
    'Enabled' : IDL.Null,
    'Disabled' : IDL.Null,
    'Readonly' : IDL.Null,
  });
  const AppState = IDL.Record({ 'mode' : AppMode });
  const Result = IDL.Variant({
    'Ok' : IDL.Null,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const FromUserRecord = IDL.Record({ 'user_id' : IDL.Principal });
  const FromCanisterRecord = IDL.Record({
    'canister_version' : IDL.Opt(IDL.Nat64),
    'canister_id' : IDL.Principal,
  });
  const CanisterChangeOrigin = IDL.Variant({
    'from_user' : FromUserRecord,
    'from_canister' : FromCanisterRecord,
  });
  const CreationRecord = IDL.Record({ 'controllers' : IDL.Vec(IDL.Principal) });
  const CodeDeploymentMode = IDL.Variant({
    'reinstall' : IDL.Null,
    'upgrade' : IDL.Null,
    'install' : IDL.Null,
  });
  const CodeDeploymentRecord = IDL.Record({
    'mode' : CodeDeploymentMode,
    'module_hash' : IDL.Vec(IDL.Nat8),
  });
  const LoadSnapshotRecord = IDL.Record({
    'canister_version' : IDL.Nat64,
    'taken_at_timestamp' : IDL.Nat64,
    'snapshot_id' : IDL.Vec(IDL.Nat8),
  });
  const CanisterChangeDetails = IDL.Variant({
    'creation' : CreationRecord,
    'code_deployment' : CodeDeploymentRecord,
    'load_snapshot' : LoadSnapshotRecord,
    'controllers_change' : CreationRecord,
    'code_uninstall' : IDL.Null,
  });
  const CanisterChange = IDL.Record({
    'timestamp_nanos' : IDL.Nat64,
    'canister_version' : IDL.Nat64,
    'origin' : CanisterChangeOrigin,
    'details' : CanisterChangeDetails,
  });
  const CanisterInfoResponse = IDL.Record({
    'controllers' : IDL.Vec(IDL.Principal),
    'module_hash' : IDL.Opt(IDL.Vec(IDL.Nat8)),
    'recent_changes' : IDL.Vec(CanisterChange),
    'total_num_changes' : IDL.Nat64,
  });
  const Result_1 = IDL.Variant({
    'Ok' : IDL.Text,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const CanisterState = IDL.Record({
    'root_id' : IDL.Opt(IDL.Principal),
    'path' : IDL.Opt(IDL.Text),
    'parent_id' : IDL.Opt(IDL.Principal),
  });
  const DeleteRequest = IDL.Record({
    'key' : IDL.Vec(IDL.Text),
    'entity' : IDL.Text,
  });
  const DeleteResponse = IDL.Record({
    'keys' : IDL.Vec(IDL.Vec(IDL.Tuple(IDL.Text, IDL.Opt(IDL.Text)))),
  });
  const Result_2 = IDL.Variant({
    'Ok' : DeleteResponse,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const LoadMethod = IDL.Variant({
    'All' : IDL.Null,
    'One' : IDL.Vec(IDL.Text),
    'Many' : IDL.Vec(IDL.Vec(IDL.Text)),
    'Only' : IDL.Null,
    'Range' : IDL.Tuple(IDL.Vec(IDL.Text), IDL.Vec(IDL.Text)),
    'Prefix' : IDL.Vec(IDL.Text),
  });
  const SortDirection = IDL.Variant({ 'Asc' : IDL.Null, 'Desc' : IDL.Null });
  const Filter = IDL.Variant({
    'All' : IDL.Text,
    'Fields' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
  });
  const LoadFormat = IDL.Variant({ 'Rows' : IDL.Null, 'Count' : IDL.Null });
  const LoadRequest = IDL.Record({
    'entity' : IDL.Text,
    'method' : LoadMethod,
    'order' : IDL.Opt(IDL.Vec(IDL.Tuple(IDL.Text, SortDirection))),
    'offset' : IDL.Nat32,
    'limit' : IDL.Opt(IDL.Nat32),
    'filter' : IDL.Opt(Filter),
    'format' : LoadFormat,
  });
  const Metadata = IDL.Record({
    'created' : IDL.Nat64,
    'modified' : IDL.Nat64,
  });
  const QueryValue = IDL.Record({
    'metadata' : Metadata,
    'data' : IDL.Vec(IDL.Nat8),
  });
  const QueryRow = IDL.Record({
    'key' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Opt(IDL.Text))),
    'value' : QueryValue,
  });
  const LoadResponse = IDL.Variant({
    'Rows' : IDL.Vec(QueryRow),
    'Count' : IDL.Nat32,
  });
  const Result_3 = IDL.Variant({
    'Ok' : LoadResponse,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const SaveRequestAction = IDL.Variant({
    'Create' : IDL.Null,
    'Update' : IDL.Null,
  });
  const SaveRequest = IDL.Record({
    'entity' : IDL.Text,
    'action' : SaveRequestAction,
    'data' : IDL.Vec(IDL.Nat8),
  });
  const CreateResponse = IDL.Record({ 'row' : QueryRow });
  const SaveResponse = IDL.Variant({
    'Create' : CreateResponse,
    'Update' : CreateResponse,
  });
  const Result_4 = IDL.Variant({
    'Ok' : SaveResponse,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Status = IDL.Variant({ 'Active' : IDL.Null, 'Archived' : IDL.Null });
  const Part = IDL.Record({
    'mesh_id' : IDL.Opt(IDL.Text),
    'modifier_ids' : IDL.Vec(IDL.Text),
    'part_id' : IDL.Text,
  });
  const Appearance = IDL.Record({
    'selection' : IDL.Vec(Part),
    'model_id' : IDL.Text,
  });
  const ActionSlot = IDL.Variant({
    'Spell' : IDL.Text,
    'Item' : IDL.Text,
    'Ability' : IDL.Text,
  });
  const Layout = IDL.Record({
    'action_bars' : IDL.Vec(
      IDL.Tuple(IDL.Nat8, IDL.Vec(IDL.Tuple(IDL.Nat8, ActionSlot)))
    ),
  });
  const Settings = IDL.Record({ 'something' : IDL.Bool });
  const Position = IDL.Record({
    'x' : IDL.Int32,
    'y' : IDL.Int32,
    'z' : IDL.Int32,
  });
  const State = IDL.Record({ 'todo' : IDL.Bool });
  const Character = IDL.Record({
    'id' : IDL.Text,
    'player_id' : IDL.Text,
    'status' : Status,
    'appearance' : Appearance,
    'name' : IDL.Text,
    'layout' : Layout,
    'settings' : Settings,
    'tokens' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Nat16)),
    'currency' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Nat32)),
    'gender_id' : IDL.Text,
    'species_id' : IDL.Text,
    'position' : Position,
    'abilities' : IDL.Vec(IDL.Tuple(IDL.Text, State)),
    'roles' : IDL.Vec(IDL.Tuple(IDL.Text, State)),
  });
  const Result_5 = IDL.Variant({
    'Ok' : Character,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Resolution = IDL.Variant({ 'R1920X1080' : IDL.Null });
  const DisplayMode = IDL.Variant({
    'Windowed' : IDL.Null,
    'Fullscreen' : IDL.Null,
  });
  const Graphics = IDL.Record({
    'contrast' : IDL.Nat8,
    'brightness' : IDL.Nat8,
    'resolution' : Resolution,
    'display_mode' : DisplayMode,
  });
  const Controls = IDL.Record({
    'mouse_invert' : IDL.Bool,
    'mouse_speed' : IDL.Nat8,
    'inventory_mouse_controls' : IDL.Bool,
    'keyboard' : IDL.Vec(
      IDL.Tuple(IDL.Text, IDL.Tuple(IDL.Opt(IDL.Text), IDL.Opt(IDL.Text)))
    ),
  });
  const Preferences = IDL.Record({ 'language_id' : IDL.Opt(IDL.Text) });
  const Sound = IDL.Record({
    'sfx_volume' : IDL.Nat8,
    'music_disabled' : IDL.Bool,
    'music_volume' : IDL.Nat8,
    'sfx_disabled' : IDL.Bool,
  });
  const Gameplay = IDL.Record({
    'show_nameplates' : IDL.Bool,
    'autoloot' : IDL.Bool,
    'show_tooltips' : IDL.Bool,
  });
  const Settings_1 = IDL.Record({
    'graphics' : Graphics,
    'controls' : Controls,
    'preferences' : Preferences,
    'sound' : Sound,
    'gameplay' : Gameplay,
  });
  const Networks = IDL.Record({
    'catalyze' : IDL.Opt(IDL.Text),
    'openchat' : IDL.Opt(IDL.Text),
  });
  const Profile = IDL.Record({
    'username' : IDL.Opt(IDL.Text),
    'networks' : IDL.Opt(Networks),
    'display_name' : IDL.Opt(IDL.Text),
  });
  const Player = IDL.Record({
    'id' : IDL.Text,
    'settings' : Settings_1,
    'active_character_id' : IDL.Opt(IDL.Text),
    'principal_' : IDL.Principal,
    'profile' : IDL.Opt(Profile),
  });
  const Result_6 = IDL.Variant({
    'Ok' : Player,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Setting = IDL.Record({
    'population_ids' : IDL.Vec(IDL.Text),
    'architecture_ids' : IDL.Vec(IDL.Text),
    'theme_ids' : IDL.Vec(IDL.Text),
    'usage_ids' : IDL.Vec(IDL.Text),
    'mood_ids' : IDL.Vec(IDL.Text),
    'culture_ids' : IDL.Vec(IDL.Text),
    'ecosystem_ids' : IDL.Vec(IDL.Text),
  });
  const Entry = IDL.Record({
    'weighting' : IDL.Float64,
    'solid_id' : IDL.Text,
  });
  const Select = IDL.Variant({
    'Custom' : IDL.Vec(Entry),
    'Preset' : IDL.Text,
  });
  const Entry_1 = IDL.Record({
    'weighting' : IDL.Float64,
    'gas_id' : IDL.Text,
  });
  const Select_1 = IDL.Variant({
    'Custom' : IDL.Vec(Entry_1),
    'Preset' : IDL.Text,
  });
  const Entry_2 = IDL.Record({
    'weighting' : IDL.Float64,
    'liquid_id' : IDL.Text,
  });
  const Select_2 = IDL.Variant({
    'Custom' : IDL.Vec(Entry_2),
    'Preset' : IDL.Text,
  });
  const Environment = IDL.Record({
    'geology' : IDL.Opt(Select),
    'atmosphere' : IDL.Opt(Select_1),
    'hydrology' : IDL.Opt(Select_2),
  });
  const Zone = IDL.Record({
    'id' : IDL.Text,
    'setting' : IDL.Opt(Setting),
    'name' : IDL.Text,
    'parent_id' : IDL.Opt(IDL.Text),
    'environment' : IDL.Opt(Environment),
  });
  const Result_7 = IDL.Variant({
    'Ok' : IDL.Vec(Zone),
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Result_8 = IDL.Variant({
    'Ok' : IDL.Vec(Character),
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Result_9 = IDL.Variant({
    'Ok' : IDL.Vec(Player),
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const UpdateProfile = IDL.Record({ 'profile' : Profile });
  const CreateCharacter = IDL.Record({
    'appearance' : Appearance,
    'name' : IDL.Text,
    'gender_id' : IDL.Text,
    'species_id' : IDL.Text,
  });
  const UpdateSettings = IDL.Record({
    'graphics' : IDL.Opt(Graphics),
    'controls' : IDL.Opt(Controls),
    'preferences' : IDL.Opt(Preferences),
    'sound' : IDL.Opt(Sound),
    'gameplay' : IDL.Opt(Gameplay),
  });
  const SetActiveCharacter = IDL.Record({ 'character_id' : IDL.Text });
  const Kind = IDL.Variant({
    'UpdateProfile' : UpdateProfile,
    'Test' : IDL.Null,
    'CreateCharacter' : CreateCharacter,
    'UpdateSettings' : UpdateSettings,
    'SetActiveCharacter' : SetActiveCharacter,
  });
  const Action = IDL.Record({ 'kind' : Kind });
  const Feed = IDL.Record({ 'item_id' : IDL.Text });
  const Rename = IDL.Record({ 'old_name' : IDL.Text, 'new_name' : IDL.Text });
  const Kind_1 = IDL.Variant({
    'Feed' : Feed,
    'Test' : IDL.Null,
    'Rename' : Rename,
  });
  const Action_1 = IDL.Record({
    'kind' : Kind_1,
    'draggin_id' : IDL.Text,
    'position' : Position,
  });
  const Kind_2 = IDL.Variant({ 'Test' : IDL.Null });
  const Action_2 = IDL.Record({ 'kind' : Kind_2, 'position' : Position });
  const Kind_3 = IDL.Variant({
    'Player' : Action,
    'Draggin' : Action_1,
    'Character' : Action_2,
  });
  const Action_3 = IDL.Record({ 'id' : IDL.Text, 'kind' : Kind_3 });
  const Request = IDL.Record({
    'player_id' : IDL.Text,
    'character_id' : IDL.Opt(IDL.Text),
    'actions' : IDL.Vec(Action_3),
  });
  const Kind_4 = IDL.Variant({ 'Empty' : IDL.Null });
  const Result_10 = IDL.Variant({ 'Ok' : Kind_4, 'Err' : IDL.Text });
  const Kind_5 = IDL.Variant({
    'Empty' : IDL.Null,
    'CreateCharacter' : SetActiveCharacter,
  });
  const Result_11 = IDL.Variant({ 'Ok' : Kind_5, 'Err' : IDL.Text });
  const Kind_6 = IDL.Variant({
    'Pet' : Result_10,
    'Player' : Result_11,
    'Character' : Result_10,
  });
  const Action_4 = IDL.Record({ 'id' : IDL.Text, 'kind' : Kind_6 });
  const Response = IDL.Record({
    'player_id' : IDL.Text,
    'character_id' : IDL.Opt(IDL.Text),
    'actions' : IDL.Vec(Action_4),
  });
  const Result_12 = IDL.Variant({
    'Ok' : Response,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Result_13 = IDL.Variant({
    'Ok' : IDL.Vec(IDL.Text),
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  return IDL.Service({
    'app_state' : IDL.Func([], [AppState], ['query']),
    'app_state_cascade' : IDL.Func([AppState], [Result], []),
    'canister_caller' : IDL.Func([], [IDL.Principal], ['query']),
    'canister_id' : IDL.Func([], [IDL.Principal], ['query']),
    'canister_info' : IDL.Func([], [CanisterInfoResponse], []),
    'canister_path' : IDL.Func([], [Result_1], ['query']),
    'canister_state' : IDL.Func([], [CanisterState], ['query']),
    'canister_time' : IDL.Func([], [IDL.Nat64], ['query']),
    'canister_upgrade_children' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [Result],
        [],
      ),
    'canister_version' : IDL.Func([], [IDL.Nat64], ['query']),
    'child_index' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Text))],
        ['query'],
      ),
    'crud_delete' : IDL.Func([DeleteRequest], [Result_2], []),
    'crud_load' : IDL.Func([LoadRequest], [Result_3], ['composite_query']),
    'crud_save' : IDL.Func([SaveRequest], [Result_4], []),
    'db_load' : IDL.Func([], [Result], ['query']),
    'fixtures_replace_all' : IDL.Func([], [Result], []),
    'get_character' : IDL.Func([IDL.Text, IDL.Text], [Result_5], ['query']),
    'get_player' : IDL.Func([IDL.Text], [Result_6], ['query']),
    'get_zones' : IDL.Func(
        [IDL.Text, IDL.Text],
        [Result_7],
        ['composite_query'],
      ),
    'ic_cycles_accept' : IDL.Func([IDL.Nat64], [IDL.Nat64], []),
    'init_async' : IDL.Func([], [Result], []),
    'init_user' : IDL.Func([IDL.Principal], [Result_1], []),
    'list_characters' : IDL.Func([IDL.Text], [Result_8], ['composite_query']),
    'list_players' : IDL.Func([], [Result_9], ['composite_query']),
    'process_actions' : IDL.Func([Request], [Result_12], []),
    'store_clear' : IDL.Func([IDL.Text], [Result], []),
    'store_keys' : IDL.Func([IDL.Text], [Result_13], ['composite_query']),
    'subnet_index' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Principal))],
        ['query'],
      ),
    'subnet_index_cascade' : IDL.Func(
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Principal))],
        [Result],
        [],
      ),
  });
};
export const init = ({ IDL }) => { return [IDL.Principal, IDL.Principal]; };
