import { useNavigate } from "react-router-dom";
import { CdnCanisterState } from "../../reducers/canisterSlice";
import { Context, clearForm } from "../../reducers/formSlice";
import { RECORDS_PATH } from "../../reducers/schemaSlice";
import { useAppDispatch } from "../../services/hooks";
import {
  Entity,
  Enum,
  Field,
  GenericMap,
  IsOrEmbedType,
  Newtype,
  Permission,
  PrimitiveType,
  RecordType,
  Schema,
  SchemaTypes,
  SortKey,
} from "./Schema";
import { decode, encode } from "cbor-x";
import FormElement from "../ui/form.element";
import { memo } from "react";

export const CONTEXT_UPDATE = "Update";
export const CONTEXT_CREATE = "Create";

export const decodeCbor = (entry: any) => {
  return decode(entry);
};

export const encodeCbor = (data: any) => {
  return encode(data);
};

export const ASSET_TYPE = "design::cdn::Cdn";
export const ASSET_RELATIONS = ["design::game::asset::Icon"];

export const getBucketCanister = (canisterData: CdnCanisterState[]): string => {
  if (!canisterData || canisterData.length === 0) {
    return "";
  }
  // console.log(canisterData);
  // console.log(typeof canisterData);
  const activeBucket = canisterData.find((c: CdnCanisterState) => c.active);
  if (!activeBucket) {
    return "";
  }
  return activeBucket.cid;
};

export const getEntityChildren = (all: Schema, entity: Entity): Entity[] => {
  return getEntities(all).filter((e: Entity) => {
    return (
      e.sort_keys &&
      e.sort_keys.length > 1 &&
      e.sort_keys.find(
        (s: SortKey) => s.entity === entityUniqueIdentifier(entity)
      )
    );
  });
};

export const getEntities = (all: Schema): Entity[] => {
  return Object.values(all).filter((e): e is Entity => e.type === "Entity");
};

export const getVisibleEntities = (all: Schema): Entity[] => {
  // console.log(Object.values(all));
  return Object.values(all).filter((e): e is Entity => {
    return (
      e.type === "Entity" &&
      ((e as Entity).crud !== undefined || (e as Entity).crud !== null)
    );
  });
};

export const getEntityUnique = (
  all: Schema,
  name: string
): undefined | Entity => {
  return Object.values(all).find(
    (e): e is Entity =>
      entityUniqueIdentifier(e) === name && e.type === "Entity"
  );
};

export const getAdminPermission = (
  all: Schema,
): undefined | string => {

  const perms =  Object.values(all).find(
    (e): e is Permission =>
      e.def.ident === 'Admin' && e.type === "Permission"
  );
  return perms?.id;
};

export const getEntityByPath = (
  all: Schema,
  name: string
): undefined | Entity => {
  return Object.values(all).find(
    (e) => entityUniqueIdentifier(e as Entity) === name && e.type === "Entity"
  ) as Entity | undefined;
};

export const getPrimitiveUnique = (
  all: Schema,
  name: string
): undefined | PrimitiveType => {
  return Object.values(all).find(
    (e): e is PrimitiveType =>
      e.type === "Primitive" && entityUniqueIdentifier(e) === name
  );
};

export const getRecordUnique = (all: Schema, name: string): RecordType => {
  const recordTy = Object.values(all).find(
    (e) => e.type === "Record" && entityUniqueIdentifier(e) === name
  );

  return recordTy as RecordType;
};

export const getEnumUnique = (all: Schema, name: string): Enum => {
  const enumTy = Object.values(all).find(
    (e) => e.type === "Enum" && entityUniqueIdentifier(e) === name
  );

  return enumTy as Enum;
};

export const getMapUnique = (all: Schema, name: string): GenericMap => {
  const mapTy = Object.values(all).find(
    (e) => e.type === "Map" && entityUniqueIdentifier(e) === name
  );

  return mapTy as GenericMap;
};


// export const getTypeData = (all: Schema, field: Field): SchemaTypes => {
//   const typePath = fieldType(field);
//   if (typePath === 'Id') {
//     // @ts-ignore
//     return 'Id';
//   }
//   if (typePath && all[typePath]) {
//     return all[typePath];
//   }

//   throw new Error(`Type ${typePath} not found in schema`);
// }


export const getTypeData = (all: Schema, typePath: string): SchemaTypes => {
  if (typePath === 'Id') {
    // @ts-ignore
    return 'Id';
  }
  if (typePath && all[typePath]) {
    return all[typePath];
  }

  throw new Error(`Type ${typePath} not found in schema`);
}

export const extractNonNewtype = (all: Schema, typeDef: Newtype): SchemaTypes => {
  if (typeDef.type === 'Newtype') {
    const typeDefVal = typeDef.value.item.is;
    return extractNonNewtype(all, (getTypeData(all, typeDefVal) as Newtype));
  }
  return typeDef;
}

export const getPrimitive = (all: Schema, field: Field): SchemaTypes => {
  const resolveNewtype = (typePath: string): any => {
    const newType = all[typePath];
    if (newType && newType.type === "Newtype") {
      return resolveNewtype((newType as Newtype).value.item.is);
    }
    return newType;
  };
  const typePath = fieldType(field);
  if (!typePath) {
    throw new Error("Type not found");
  }
  const initialType = getTypeData(all, typePath);
    
  if (initialType.type === "Newtype") {
    const typeDef = resolveNewtype(typePath);
    return typeDef;
  }
  return initialType;

};

export const extractVariantName = (variantObject: Object) =>
  Object.keys(variantObject)[0];

export const isPrimitive = (schemaType: SchemaTypes): boolean => {
  return schemaType.type === "Primitive";
};

export const isRecord = (schemaType: SchemaTypes): boolean => {
  return schemaType.type === "Record";
};

export const isEnum = (schemaType: SchemaTypes): boolean => {
  return schemaType.type === "Enum";
};

export const isMap = (schemaType: SchemaTypes): boolean => {
  return schemaType.type === "Map";
};

export const isTuple = (schemaType: SchemaTypes): boolean => {
  return schemaType.type === "Tuple";
};

export const isListSet = (schemaType: SchemaTypes): boolean => {
  return schemaType.type === 'Newtype' &&
    ((schemaType as Newtype).primitive === null || (schemaType as Newtype).primitive === undefined)
     && (schemaType as Newtype).value.cardinality === 'Many';
};

export const serviceDistributed = (e: Entity) => {
  return e.is_distributed;
};

// export const fieldType = (field: Field): string | undefined => {
//   if (typeof field.value.item === 'string') {
//     return field.value.item;
//   }
//   return field.value.item?.is;
// };

export const fieldType = (field: Field): string => {
  if (typeof field.value.item === 'string') {
    return field.value.item;
  }
  if (field.value.item.selector && field.value.item.selector !== '') {
    return field.value.item.selector;
  }
  return Object.values(field.value.item)[0];
};


// export const isOrEmbedType = (item: IsOrEmbedType): string | undefined => {
//   return item.Is?.path || item.Embed?.path;
// };

export const fieldRelation = (field: Field): string | undefined => {
  if (typeof field.value.item === 'string') {
    return;
  }
  return field.value?.item?.relation;
};

export const fieldCardinalityMany = (field: Field): boolean => {
  return field.value.cardinality === "Many";
};

export const fieldCardinalityOpt = (field: Field): boolean => {
  return field.value.cardinality === "Opt";
};

export const wrapNullable = (value: any, field: Field) => {
  if (Array.isArray(value)) {
    return value;
  }

  const newVal =
    value === "" || value === undefined
      ? null
      : fieldCardinalityOpt(field) && value === null
      ? null
      : [value];

  if (fieldCardinalityMany(field)) {
    return newVal;
  }
  return fieldCardinalityOpt(field) ? newVal : value;
};

export const deserialiseData = (dataArr: any[]): any[] => {
  return dataArr.map(data => {
    const newObj: any = {};

    for (let objKey in data) {
      const value = data[objKey];

      // Handling BigInt values
      if (typeof value === "bigint") {
        newObj[objKey] = Number(value); // Append 'n' to indicate bigint type
      } 
      // Handling objects, including checking for special cases
      else if (typeof value === "object" && value !== null) {
        if (value._isPrincipal) {
          newObj[objKey] = value.toString(); // Convert Principal to string if applicable
        } else if (value instanceof Uint8Array) {
          newObj[objKey] = Array.from(value).join(', '); // Convert Uint8Array to comma-separated string
        } else {
          // Recursive call for nested objects or arrays
          newObj[objKey] = Array.isArray(value) ? value.map(item => typeof item === 'object' ? deserialiseData([item])[0] : item) : deserialiseData([value])[0];
        }
      } 
      // Handle all other data types as is
      else {
        newObj[objKey] = value;
      }
    }

    return newObj;
  });
};

export const b64toBlob = (
  b64Data: string,
  contentType = "",
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const b64toUint8 = (b64Data: string) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays[i] = byteCharacters.charCodeAt(i);
  }
  return new Uint8Array(byteArrays);
};

export const encodeBase64 = async (blob: Blob): Promise<Blob> => {
  return new Promise<any>((resolve) => {
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result);
    };
  });
};

export const headersBase64 = (file: any): string => {
  let encoded = file.toString().replace(/^data:(.*,)?/, "");
  if (encoded.length % 4 > 0) {
    encoded += "=".repeat(4 - (encoded.length % 4));
  }

  return encoded;
};

export const encodeArrayBuffer = (file: ArrayBuffer): Uint8Array =>
  new Uint8Array(file);

export const MAX_CHUNK_SIZE = 1024 * 700; // 700kb

export const encodeFilename = (filename: string): string =>
  encodeURI(filename.toLowerCase().replace(/\s/g, "-"));

export const decodeData = (data: any[]) => {
  let jsonData: any = [];
  for (const rowData of data) {
    // console.log(rowData.value.data);
    // console.log(decodeCbor(rowData.value.data));
    const data = {
      ...decodeCbor(rowData.value.data),
      created: Number(rowData.value.metadata.created),
      modified: Number(rowData.value.metadata.modified),
    };
    jsonData.push(data);
  }
  return jsonData;
};

export const getFileExtension = (type: string): any | null => {
  switch (type) {
    case "image/jpeg":
      return { jpeg: null };
    case "image/gif":
      return { gif: null };
    case "image/jpg":
      return { jpg: null };
    case "application/pdf":
      return { jpg: null };
    case "image/png":
      return { png: null };
    case "image/svg":
      return { svg: null };
    case "video/avi":
      return { avi: null };
    case "video/aac":
      return { aac: null };
    case "video/mp4":
      return { mp4: null };
    case "audio/wav":
      return { wav: null };
    case "audio/mp3":
      return { mp3: null };
    default:
      return null;
  }
};

export const getReverseFileExtension = (type: { string: null }): string => {
  if (emptyOrNull(type)) {
    return "";
  }
  switch (Object.keys(type)[0]) {
    case "jpeg":
      return "image/jpeg";
    case "gif":
      return "image/gif";
    case "jpg":
      return "image/jpg";
    case "pdf":
      return "application/pdf";
    case "png":
      return "image/png";
    case "svg":
      return "image/svg";
    case "avi":
      return "video/avi";
    case "mp4":
      return "video/mp4";
    case "aac":
      return "video/aac";
    case "wav":
      return "audio/wav";
    case "mp3":
      return "audio/mp3";
    default:
      return "";
  }
};

export const getFileFolder = (type: string): any | null => {
  switch (type) {
    case "image/jpeg":
    case "image/gif":
    case "image/jpg":
    case "image/png":
    case "image/png":
    case "image/svg":
      return "images";
    case "application/pdf":
      return "files";
    case "video/avi":
    case "video/aac":
    case "video/mp4":
      return "videos";
    case "audio/wav":
    case "audio/mp3":
      return "audio";
    default:
      return null;
  }
};

const backAction = (dispatch: any, url: string, navigate: any) => {
  dispatch(clearForm());
  navigate(`/${RECORDS_PATH}/${url.split("/")[2]}`);
};

interface FormHeader {
  entity: Entity;
  url: string;
}
export const FormHeader = (props: FormHeader & Context): JSX.Element => {
  const dispatch = useAppDispatch();
  const { entity, context, url } = props;
  let navigate = useNavigate();
  return (
    <div className="row">
      <div className="col-6">
        <h5>
          {context} {entityName(entity)} -- {entityUniqueIdentifier(entity)}
        </h5>
      </div>
      <div className="col-6">
        <button
          onClick={() => backAction(dispatch, url, navigate)}
          className="btn btn-lg btn-info float-end"
        >
          <i className="bi bi-arrow-left-square-fill"></i> Back to List
        </button>
      </div>
    </div>
  );
};

interface FormElementsProps {
  entity: Entity;
  data: any[];
}
export const FormElementsComp = (props: FormElementsProps): JSX.Element => {
  const { entity, data } = props;
  return <FormBody fields={Object.values(entityFields(entity))} data={data} />;
};

export const FormElements = memo(FormElementsComp)

interface FormBody {
  fields: Field[];
  data: any[];
}
const FormBodyComp = (props: FormBody): JSX.Element => {
  const { fields, data } = props;
  let sortFields = rearrangeFields(fields);

  return (
    <div>
      {sortFields.map((field: Field) => (
        <FormRow key={fieldName(field)} field={field} data={data} />
      ))}
    </div>
  );
};

export const FormBody = memo(FormBodyComp);

interface FormRow {
  field: Field;
  data: any[];
}
const FormRowComp = ({ field, data }: FormRow): JSX.Element => {
  return (
    <div className={"row"}>
      <FormElement field={field} data={data} extras={""} />
    </div>
  );
};

export const FormRow = memo(FormRowComp);

export const bigNumberStringToBigInt = (value: string) => {
  if (typeof value === "string") {
    const m = value.match(/(-?\d+)n/);
    // @ts-ignore
    if (m && m[0] === value) {
      return BigInt(m[1]);
    }
  }
  return value;
};

export const constructFieldFromType = (
  type: IsOrEmbedType,
  cardinality: string,
  name: string
): Field => {
  return {
    skip_validate: false,
    name: name,
    value: {
      item: type,
      cardinality: cardinality,
    },
  };
};

export const toNullable = function <T>(value?: T): [] | [T] {
  return value ? [value] : [];
};

export const fromNullable = function <T>(value: [] | [T]): T | undefined {
  return value?.[0];
};

export const emptyOrNull = (value: any) => {
  return value === undefined || value === "" || value === null;
};

export const getStateNameExtra = (field: Field, extras: String): string => {
  if (extras.length > 0 && extras[extras.length - 1] === "-") {
    return extras + fieldName(field);
  }
  return emptyOrNull(extras)
    ? fieldName(field)
    : extras + "-" + fieldName(field);
};

export const entityFields = (e: Entity): Field[] => {
  return Object.values(e.fields.fields);
};

export const entityUniqueIdentifier = (entity: SchemaTypes) => {
  return entity.id;
};

export const entityName = (entity: Entity | RecordType) => {
  return entity.def.ident;
};

export const toSnakeCase = (str: String): String => {
  return str.split("::").join("_").toLowerCase();
};

export const fieldName = (field: Field): string => {
  return field.name;
};

export const serviceName = (entity: Entity): string => {
  return entity.actorName;
};

export const servicePath = (entity: Entity): string => {
  return entity.actorPath;
};

export const readOnlyEntity = (entity: Entity): boolean => {
  // console.log(entity.crud);
  return !(entity.crud && entity.crud.save && (entity.crud.save as any).Permission);
};

export const rearrangeFields = (fields: Field[]): Field[] => {
  return fields;
};

export const checkError = (response: any): string => {
  if (response.Ok) {
    return "";
  }
  if (response.Err.Auth && response.Err.Auth.Whitelist === null) {
    return "Not allowed";
  }
  return JSON.stringify(response.Err);
};
