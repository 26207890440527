import { FC, memo, useCallback, useContext, useEffect, useState } from "react";
import { EnvironmentContext } from "../../..";
import { Entity, Field } from "../crud/Schema";
import { useAppDispatch, useAppSelector } from "../../services/hooks";
import {
  FormElementState,
  formState,
  setFormValue,
} from "../../reducers/formSlice";
import {
  relationApi,
  useGetRelationQuery,
} from "../../reducers/crud/relationSlice";
import { Backend } from "../../services/api/backend";
import {
  CONTEXT_UPDATE,
  emptyOrNull,
  entityName,
  fieldCardinalityMany,
  fieldCardinalityOpt,
  fieldName,
  fieldRelation,
  getEntityByPath,
  getEntityUnique,
  getReverseFileExtension,
  getStateNameExtra,
  serviceName,
} from "../crud/Base";
import validateField from "../../services/helpers/validation";
import { MenuPlacement, components } from "react-select";
import AsyncSelect from "react-select/async";
import { ASSET_NAME, AssetViewer } from "./asset";
import { cdnApi, useGetAllFilesQuery } from "../../reducers/cdnSlice";
import { useCdnCanistersQuery } from "../../reducers/canisterSlice";
import { getCanisterIdByName, useActiveCanisters } from "../layout";
import { flattenRows } from "../crud/List";
import { loadState } from "../../reducers/schemaSlice";
import { AssetKey } from "../../../declarations/cdn_bucket/cdn_bucket.did.t";
import LoadingSpinner from "./spinner";
import { GetMethod, crudGetApi } from "../../reducers/crud/getSlice";
import { Nullable } from "./nullable";
import { debounce } from "lodash";

interface RelationElement {
  field: Field;
  parentRef?: Entity;
  width: number;
  extras: string;
  data: any;
}
const RelationElementComponent: React.FC<RelationElement> = (
  props: RelationElement
) => {
  const { environment } = useContext(EnvironmentContext);
  const [defaultOptions, setDefaultOptions] = useState<any>([]);
  const [defaultOption, setDefaultOption] = useState<any>(null);
  const [search, setSearch] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [nullValue, setNullValue] = useState(true);
  const { field, width, extras, data } = props;
  const relationType = fieldRelation(field);
  if (!relationType) {
    return <p> Field {fieldName(field)} needs a Relation! </p>;
  }
  const schema = loadState();
  const parentRef = getEntityByPath(schema, relationType);
  if (!parentRef) {
    return <p> Field {fieldName(field)} needs a RefEntity! </p>;
  }
  const { main } = useActiveCanisters();
  const dispatch: any = useAppDispatch();
  const canisterId = getCanisterIdByName(
    parentRef,
    environment,
    useActiveCanisters().main
  );
  const { cdn: activeCdn } = useActiveCanisters();
  const {
    data: cdnAll = [],
    error: cdnErr,
    isLoading: cdnLoading,
  } = useGetAllFilesQuery(
    {
      cid: activeCdn,
    },
    { skip: activeCdn === "" }
  );

  const { form, context } = useAppSelector(formState);

  const multiSelect = fieldCardinalityMany(field);

  const handleChange = (selectedOption: any) => {
    console.log(selectedOption);
    const value = selectedOption.value;
    const vals = [];
    if (multiSelect) {
      for (let so of selectedOption) {
        vals.push(so.value);
      }
    }
    console.log(vals);
    const errs = validateField(field, value);
    console.log(errs);
    dispatch(
      setFormValue({
        key: getStateNameExtra(field, extras),
        value: multiSelect ? vals : value,
        error: errs,
        extras: getStateNameExtra(field, extras),
      })
    );
    setErrors(errs);
  };
  // console.log(nullValue);
  const nullableClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    const checked: boolean = target.checked;
    setNullValue(checked);
    const stateValue: FormElementState | undefined = form.find(
      (f: FormElementState) => f.key === getStateNameExtra(field, extras)
    );
    if (stateValue) {
      const newValue = checked ? "" : null;
      const errs = validateField(field, "", !checked);
      setErrors(errs);
      if (checked) {
        setDefaultOption(null);
      }
      dispatch(setFormValue({ ...stateValue, value: newValue, error: errs }));
    }
  };

  useEffect(() => {
    const nullable = fieldCardinalityOpt(field);
    if (context === CONTEXT_UPDATE && data) {
      if (data.key === getStateNameExtra(field, extras)) {
        let updateValue = data.value;
        // console.log(data);
        // console.log(updateValue);

        const initialObj: FormElementState = {
          key: getStateNameExtra(field, extras),
          value: updateValue,
          error: [],
          extras: getStateNameExtra(field, extras),
        };
        dispatch(setFormValue(initialObj));
        if (updateValue === null) {
          setNullValue(false);
          setLoaded(true);
          return;
        }
        const fetchData = async (method: GetMethod) => {
          const relationsResponse = await dispatch(
            crudGetApi.endpoints.getByIds.initiate({
              e: parentRef,
              cid: canisterId,
              method: method,
            })
          );
          // console.log(relationsResponse);
          if (relationsResponse.isError) {
            return null;
          }
          return relationsResponse.data;
        };

        if (multiSelect) {
          // console.log(updateValue);
          const mapMultiValues = updateValue.map((v: string) => [v]);
          console.log(mapMultiValues);
          fetchData({ Many: mapMultiValues })
            .then((dataNew) => {
              console.log(dataNew);
              // console.log(dataNew.id);
              // const newDataDisplay =
              //   dataNew === null
              //     ? updateValue
              //     : `${dataNew.id} - ${JSON.stringify(dataNew.name)}`;
              // console.log(updateValue);
              // console.log(newDataDisplay);
              // console.log(parentRef.display);
              // setDefaultOption({
              //   value: updateValue[0],
              //   label: newDataDisplay,
              // });
              const multiValues = updateValue.map((v: string) => {
                // console.log(v);
                const fullData = dataNew.find((data: any) => data.id === v);
                // console.log(fullData);
                let label = "";
                if (parentRef.display && parentRef.display.length > 0) {
                  for (let displayVal of parentRef.display) {
                    label += fullData["id"] + " - " + fullData[displayVal];
                  }
                } else {
                  label = fullData["id"];
                  fullData["name"] ? (label += " - " + fullData["name"]) : "";
                }

                // console.log(label);
                // console.log(fullData);
                return {
                  value: v,
                  label: label,
                };
              });
              // console.log(multiValues);
              setDefaultOption(multiValues);
              setLoaded(true);
              // Handle the dataNew here
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          // console.log(updateValue);

          fetchData({ One: [updateValue] })
            .then((dataNew) => {
              // console.log(dataNew.name);
              // console.log(dataNew.id);
              const newDataDisplay =
                dataNew === null
                  ? updateValue
                  : `${dataNew.id} - ${JSON.stringify(dataNew.name)}`;
              setDefaultOption({ value: updateValue, label: newDataDisplay });
              setLoaded(true);
              // Handle the dataNew here
            })
            .catch((error) => {
              console.log(error);
            });
        }

        // console.log(data.value);

        // console.log(field);
      }
      // setLoaded(true);
    } else {
      if (nullable) {
        setNullValue(false);
      }
      // console.log(nullValue);
      const errs = validateField(field, "", nullable);
      setErrors(errs);
      // console.log(errs);
      const initialObj: FormElementState = {
        key: getStateNameExtra(field, extras),
        value: nullable ? null : multiSelect ? [] : "",
        error: errs,
        extras: getStateNameExtra(field, extras),
      };
      dispatch(setFormValue(initialObj));
      setLoaded(true);
    }
  }, []);

  const loadOptions = async (inputValue: string): Promise<any> => {
    console.log(inputValue);
    try {
      const canisterId = getCanisterIdByName(parentRef, environment, main);
      const relationsResponse = await dispatch(
        relationApi.endpoints.getRelation.initiate({
          entity: parentRef,
          actorName: parentRef.actorName as keyof Backend.ActorTypes,
          cid: canisterId,
          filter: { All: inputValue },
        })
      );
      // console.log(relationsResponse.data);
      const flattenData: any = flattenRows(
        schema,
        parentRef,
        relationsResponse.data
      );
      console.log(flattenData);
      console.log(mapOptionsToValues(flattenData, parentRef, cdnAll));
      return mapOptionsToValues(flattenData, parentRef, cdnAll);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handleDropdownOpen = (): any => {
    const selectElement = document.getElementById(
      getStateNameExtra(field, extras)
    );
    if (!selectElement) {
      return "auto";
    }
    const rect = selectElement.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom;
    const dropdownHeight = 250; // Estimate or calculate the height of your dropdown
    if (spaceBelow < dropdownHeight) {
      return "top";
    }

    return "auto";
  };

  // const defaultVal = defaultValue(defaultOption, defaultOptions, parentRef);
  // console.log(defaultOption);
  return (
    <div className="row g-1">
      <div className="col-md-1">
        <label className="text-break">
          <small>
            <strong>{fieldName(field)}</strong>
          </small>
        </label>
      </div>
      {fieldCardinalityOpt(field) && (
        <div className="col-md-1 d-flex justify-content-center">
          <Nullable field={field} checked={nullValue} onChange={nullableClick} />
        </div>
      )}
      {nullValue === true && loaded && (
        <div className={`col-md-${width}`}>
          <div className="form-group" id={getStateNameExtra(field, extras)}>
            <AsyncSelect
              className={`${
                errors.length === 0 ? "" : "relationship-required"
              }`}
              defaultValue={defaultOption}
              onChange={handleChange}
              loadOptions={loadOptions}
              menuPlacement={handleDropdownOpen()}
              isSearchable
              components={{ Option }}
              isMulti={multiSelect}
              loadingMessage={() => "Loading..."}
              placeholder={`You need to type at least 1 characters in order to fetch...`}
              noOptionsMessage={() => "No Results! Please refine your search"}
            />
            <strong>
              <small className="text-info">
                This is a {multiSelect ? "multi" : "single"} select for{" "}
                {fieldRelation(field)}
              </small>
            </strong>
            <br />
          </div>
          {errors.length > 0 && (
            <div className="invalid-feedback">
              {errors.map((err, i) => (
                <span key={i}>
                  <strong> - {err}</strong>{" "}
                </span>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const RelationElement = memo(RelationElementComponent);

const Option: any = (props: any) => {
  const { data } = props;
  console.log(data);
  const customDisplayRelations = ["Icon"];

  if (!customDisplayRelations.includes(data.relation.name)) {
    return (
      <components.Option {...props}>
        <p>{data.label}</p>
      </components.Option>
    );
  }
  // console.log(data);
  return (
    <components.Option {...props}>
      <p>
        {" "}
        {data.label}{" "}
        <AssetViewer
        cdnData={data.cdnData}
          id={data.relation.asset.value}
          size={"35"}
        />{" "}
      </p>
    </components.Option>
  );
};

export const mapOptionsToValues = (
  options: any,
  relationDef: Entity,
  cdnData: AssetKey[]
) => {
  const imgStyle = {
    maxWidth: "30px",
  };
  if (!options) {
    return [];
  }
  console.log(options);
  return options.map((option: any) => {
    // console.log(option);
    const id = option.find((e: any) => e.key === "id");
    // console.log(id);
    const name = option.find((e: any) => e.key.includes("name"));
    // console.log(name);
    const assetId = option.find((e: any) => e.key === ASSET_NAME);
    return {
      value: id.value,
      label:
        name && !emptyOrNull(name.value)
          ? name.value + " - " + id.value
          : id.value,
      relation: {
        name: entityName(relationDef),
        asset: assetId ? assetId : "",
      },
      cdnData: cdnData,
    };
  });
};

interface RelationAssetViewer {
  field: Field;
  relationKey: any;
  size?: string;
  cdnData: AssetKey[];
}
const RelationAssetViewerComponent: FC<RelationAssetViewer> = (
  props: RelationAssetViewer
) => {
  const { field, relationKey, size, cdnData } = props;
  const fieldRel = fieldRelation(field);
  const { environment } = useContext(EnvironmentContext);
  const schema = loadState();
  if (!fieldRel) {
    return <div></div>;
  }
  const parent = getEntityUnique(schema, fieldRel);
  if (!parent) {
    return <div></div>;
  }
  const canisterId = Backend.getCanisterIdByName(
    serviceName(parent),
    environment
  );

  const {
    data: relationData,
    error: relationErr,
    isLoading: relationLoading,
  } = useGetRelationQuery(
    {
      actorName: serviceName(parent) as keyof Backend.ActorTypes,
      entity: parent,
      cid: canisterId,
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {}, []);
  // console.log(relationData);
  // console.log(relationKey);
  let url = undefined;
  if (relationData && relationData.length > 0) {
    const sr = relationData.find((row: any) => row.id === relationKey);
    if (sr) {
      // console.log(sr);
      // console.log(cdnData);
      const assetId = sr[ASSET_NAME];
      // console.log(assetId);
      const assetData = cdnData.find((e) => e.id[0] === assetId);
      // console.log(assetData);
      if (assetData) {
        // @ts-ignore
        const blobrec = new Blob(
          [
            new Uint8Array(
              (assetData.preview as any)
                .split(",")
                .map((x: any) => parseInt(x, 10))
            ),
          ],
          { type: getReverseFileExtension((assetData as any).extension) }
        );
        // console.log(blobrec);
        url = URL.createObjectURL(blobrec);
      }
    }
  }
  // console.log(url);
  const sizePx = {
    maxHeight: (size ? size : "") + "px",
  };
  const overlayClass = relationLoading ? "d-flex" : "d-none";
  return (
    <div className="col-md-6">
      <div id="overlay" className={overlayClass}>
        <div className="w-100 d-flex justify-content-center align-items-center">
          <LoadingSpinner altText="loading" theme="lg" />
        </div>
      </div>
      <div className="form-group">
        {url && <img style={sizePx} src={url} alt="Description" />}
      </div>
    </div>
  );
};

export const RelationAssetViewer = memo(RelationAssetViewerComponent);