import React, { FC, useContext, useEffect, useState } from "react";
import { EnvironmentContext } from "../../..";
import Select from "react-select";
import { usePlayerCanistersQuery } from "../../reducers/canisterSlice";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  emptyOrNull,
  entityFields,
  entityName,
  entityUniqueIdentifier,
  fieldCardinalityMany,
  fieldName,
  getEntityChildren,
  getEntityUnique,
  getStateNameExtra,
  readOnlyEntity,
  rearrangeFields,
  serviceDistributed,
  serviceName,
} from "./Base";
import { RECORDS_PATH, loadState } from "../../reducers/schemaSlice";
import { Entity, Field, PrimitiveType, Schema } from "./Schema";
import { Backend } from "../../services/api/backend";
import {
  crudListApi,
  useGetListQuery,
  useTotalCountQuery,
} from "../../reducers/crud/listSlice";
import LoadingSpinner from "../ui/spinner";
import { useDeleteByIdMutation } from "../../reducers/crud/deleteSlice";
import moment from "moment";
import { AssetViewer } from "../ui/asset";
import { ColourViewer } from "../ui/colourPicker";
import { flattenDataRecursive } from "./DataProcessor";
import { useAppDispatch } from "../../services/hooks";
import { useGetAllFilesQuery } from "../../reducers/cdnSlice";
import { AssetKey } from "../../../declarations/cdn_bucket/cdn_bucket.did.t";
import { getCanisterIdByName, useActiveCanisters } from "../layout";
import { RelationAssetViewer } from "../ui/relation";
import { formTypeValue } from "../ui/form.element";
import { ListViewer } from "../fragments/list-viewer";
import { Filter, Filters } from "../fragments/filters";
import { UserCanisterName } from "../App";

const pageSize = 20;

export function LazyLoadAsyncSelect({ onChange, entity }: any) {
  const { environment } = useContext(EnvironmentContext);
  const [selectedValue, setSelectedValue] = useState(null);
  const [options, setOptions] = useState<any>([]);
  const [isFocused, setIsFocused] = useState(false);
  const canisterId = getCanisterIdByName(
    entity,
    environment,
    useActiveCanisters().main,
    UserCanisterName
  );
  // console.log(canisterId);
  const { data: canisters, isLoading } = usePlayerCanistersQuery(canisterId, {
    skip: !isFocused,
  });
  console.log(canisters);
  useEffect(() => {
    if (canisters) {
      const newOptions = canisters.map((canister) => ({
        label: canister.name,
        value: canister.cid,
      }));
      setOptions(newOptions);
    }
  }, [canisters]);

  function _onChange(selectedOption: any, { action }: any) {
    if (action === "select-option" || action === "input-change") {
      onChange(selectedOption.value);
      setSelectedValue(selectedOption);
    }
  }

  useEffect(() => { }, [selectedValue]);

  // console.log(selectedValue);
  return (
    <Select
      value={selectedValue}
      options={options}
      onFocus={() => setIsFocused(true)}
      onChange={_onChange}
      isLoading={isLoading}
    />
  );
}

interface ListProps {
  children?: JSX.Element;
}

const List: FC<ListProps> = (props: ListProps) => {
  const { environment } = useContext(EnvironmentContext);
  let { model } = useParams();
  let { pathname: url } = useLocation();
  // console.log(process.env)
  const schema = loadState();
  const entity: Entity | undefined = getEntityUnique(schema, model as string);
  if (!entity) {
    return <h3>Entity {model} not found!</h3>;
  }
  // console.log(entity);
  let pathParts = url.split("/");
  const pkArray =
    pathParts.length > 3
      ? pathParts
        .slice(3)
        .map((part) => decodeURIComponent(part))
        .filter((part) => part !== "")
      : [];
  const entityChildren = getEntityChildren(schema, entity);
  const entityUnique = entityUniqueIdentifier(entity);
  // console.log(entityUnique);
  const multiView = serviceDistributed(entity);

  const canisterId = getCanisterIdByName(
    entity,
    environment,
    useActiveCanisters().main
  );
  // console.log(canisterId);
  // console.log(multiView);
  // if ((canisterId === "" || canisterId === undefined) && !multiView) {
  //   return <></>;
  // }

  // console.log(entity);
  const defaultCanisterId = multiView ? "" : canisterId;
  const [selectedCid, setSelectedCid] = useState(defaultCanisterId);
  const [page, setPage] = useState(1);
  const [activeFilters, setActiveFilters] = useState<Filter | undefined>(undefined);
  // console.log(selectedCid);
  const {
    data: listData,
    error: listErr,
    isLoading: listLoading,
  } = useGetListQuery(
    {
      entity: entity,
      actorName: serviceName(entity) as keyof Backend.ActorTypes,
      cid: selectedCid,
      page: page,
      size: pageSize,
      pks: pkArray,
      filter: activeFilters,
    });
  const {
    data: countData,
    error: countError,
    isLoading: countIsLoading,
  } = useTotalCountQuery({ e: entity, cid: selectedCid });

  useEffect(() => {
    setPage(1);
    // console.log(listData);
    if (selectedCid !== defaultCanisterId && !multiView) {
      setSelectedCid(defaultCanisterId);
    }
    if (multiView) {
      setSelectedCid("");
    }
  }, [selectedCid, entityUnique]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      setActiveFilters(undefined);
    };
  }, []);

  const pageClicked = async (page: number) => {
    setPage(page);
    await dispatch(crudListApi.util.resetApiState());
  };

  const handleChange = (selectedOption: string) => {
    console.log("call change...");
    // console.log(selectedOption);
    setSelectedCid(selectedOption);
  };

  const onApplyFilters = async (filters: Filter) => {
    // console.log("apply filters");
    // console.log(filters);
    await dispatch(crudListApi.util.resetApiState());
    setActiveFilters(filters);
  };

  const getViewport = (): number => {
    const width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    // console.log(width);
    if (width <= 576) return 6;
    if (width <= 768) return 8;
    if (width <= 992) return 10;
    if (width <= 1200) return 12;
    if (width <= 1400) return 14;
    if (width <= 2000) return 15;
    if (width <= 2500) return 18;
    if (width <= 3000) return 20;
    return 22;
  };
  // console.log(selectedCid);
  return (
    <div className="row">
      <div className="col">
        <div className="col-12 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h4 className="card-title">
            {entityName(entity)} -- {entityUnique} -- {selectedCid}
          </h4>
          {!readOnlyEntity(entity) && (
            <div className="btn-toolbar mb-2 mb-md-0">
              <div className="btn-group me-2">
                {selectedCid !== "" && (
                  <Link
                    to={
                      pkArray.length > 0
                        ? `/${linkedUrl(
                          pksToString(pkArray),
                          entityUniqueIdentifier(entity),
                          "create"
                        )}`
                        : `${url}/create`
                    }
                  >
                    <button className="btn btn-primary mb-0">
                      Create <strong>{entityName(entity)}</strong>
                    </button>
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="accordion col-12" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                <strong>Filters</strong>
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <Filters fields={entityFields(entity)} onApplyFilters={onApplyFilters} />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          {multiView && (
            <div className="card">
              <div className="card-header">
                <LazyLoadAsyncSelect onChange={handleChange} entity={entity} />
              </div>
            </div>
          )}
          <div className="card-body overflow-auto">
            <table
              id="admin-list"
              className="table table-responsive table-striped"
            >
              <thead>
                <tr>
                  <TableHeaders
                    entityChildren={entityChildren}
                    entity={entity}
                  />
                </tr>
              </thead>
              <tbody>
                {selectedCid !== "" &&
                  (countIsLoading || listLoading ? (
                    <tr>
                      <td colSpan={getViewport()}>
                        <LoadingSpinner altText="loading" theme="lg" />
                      </td>
                    </tr>
                  ) : listErr !== undefined ? (
                    <tr className="text-center">
                      <td colSpan={getViewport()}>
                        <div className="alert alert-danger" role="alert">
                          {JSON.stringify(listErr)}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <TableData
                      entityChildren={entityChildren}
                      cid={selectedCid}
                      url={url}
                      e={entity}
                      page={page}
                      data={listData}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {!countIsLoading && typeof countData === "number" && (
          <TablePagination
            currentPage={page}
            totalPages={Math.ceil(countData / pageSize)}
            pageClicked={pageClicked}
            total={countData}
          />
        )}
      </div>
    </div>
  );
};

export default List;

export const flattenRows = (schema: Schema, e: Entity, data: any[]) => {
  console.log('called');
  const flattenData: any = [];
  for (let rowData of data) {
    const flatten = flattenDataRecursive(schema, e, rowData, "");
    flattenData.push(flatten);
  }

  return flattenData;
};

interface TableHeaders {
  entity: Entity;
  entityChildren: Entity[];
}
const TableHeaders: FC<TableHeaders> = ({
  entity,
  entityChildren,
}: TableHeaders): JSX.Element => {
  // console.log(entity);
  const fields: Field[] = entityFields(entity);
  // console.log(fields);
  let actions = [];

  if (entityChildren.length > 0) {
    actions.push({ name: "Embed", Key: "pk" });
  }

  actions.push({ name: "Actions", Key: "action" });
  const listFields = [...rearrangeFields(fields), ...actions];

  return (
    <>
      {listFields.map((field: Field & any) => (
        <th className="table-success" key={fieldName(field) + "_key"}>
          {" "}
          {fieldName(field)}{" "}
        </th>
      ))}
    </>
  );
};

const linkedUrl = (
  id: string,
  entityName: string,
  actionName?: string
): string => {
  if (emptyOrNull(actionName)) {
    return `${RECORDS_PATH}/${entityName}/${id}`;
  }
  return `${RECORDS_PATH}/${entityName}/${actionName}/${id}`;
};

const pksToString = (pk: string[]): string => {
  return pk.join("/");
};

interface TableData {
  url: string;
  e: Entity;
  page: number;
  cid: string;
  data: any;
  entityChildren: Entity[];
}
const TableData: FC<TableData> = (props): JSX.Element => {
  const { e, url, cid, data, entityChildren } = props;
  // console.log(cid);
  const { cdn: activeCdn } = useActiveCanisters();
  // console.log(activeCdn);
  // console.log(isLoading);
  // console.log(data);
  const {
    data: cdnAll = [],
    error: cdnErr,
    isLoading: cdnLoading,
  } = useGetAllFilesQuery(
    {
      cid: activeCdn,
    },
    { skip: activeCdn === "" }
  );
  const idVal = (row: any) => {
    // console.log(row);
    if (e.sort_keys && e.sort_keys.length > 0) {
      for (const key of e.sort_keys) {
        if (key.field !== '' && key.field !== null) {
          return row[key.field];
        }
      }
    }
    return "";
  };

  const editUrl = (id: string): string => {
    return `${url}/edit/${id}`;
  };

  const fields = entityFields(e);

  let sortFields = rearrangeFields(fields);
  if (data !== undefined) {
    var items = (data as any).map((row: any, i: number) => (
      <tr key={i}>
        {sortFields.map((field: Field) => (
          <TableRow
            key={fieldName(field)}
            cdnData={cdnAll}
            initialData={data[i]}
            rowData={row}
            field={field}
          />
        ))}
        {entityChildren.length > 0 &&
          entityChildren.map((e: Entity) => (
            <EntityChildren
              key={entityUniqueIdentifier(e)}
              e={e}
              cid={cid}
              id={idVal(row)}
              url={linkedUrl(idVal(row), entityUniqueIdentifier(e))}
              readOnly={false}
            />
          ))}
        <TableActions
          e={e}
          cid={cid}
          id={idVal(row)}
          url={editUrl(idVal(row))}
          readOnly={false}
        />
      </tr>
    ));

    return <>{items}</>;
  }
  return <></>;
};

interface TableRowDef {
  field: Field;
  rowData: any;
  cdnData: AssetKey[];
  initialData: any[];
}
const TableRow: FC<TableRowDef> = (props): JSX.Element => {
  const { field, rowData, cdnData, initialData } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  // console.log(rowData);
  // let typeView = "";
  // console.log(rowData);
  let rowValue = rowData[getStateNameExtra(field, "")];
  const { formType, parentRef, alpha } = formTypeValue(field);


  //  {moment(Number(data.value) * 1000).format("YYYY-MM-DD hh:mm:ss")}
  // let partialValue = null;
  // if (data && data.value !== null) {
  //   let blobStr = data.value.join(", ");
  //   if (Array.isArray(data.value)) {
  //     blobStr = data.value.toString();
  //   }
  //   partialValue =
  //     blobStr.substring(0, 4) +
  //     "..." +
  //     blobStr.substring(blobStr.length - 4);
  // }
  // console.log(formType);
  // console.log(rowValue);
  switch (formType) {
    case "Asset":
      return (
        <td>
          <AssetViewer id={rowValue} size={"50"} cdnData={cdnData} />
        </td>
      );
    case "RelationAsset":
      return (
        <td>
          <RelationAssetViewer
            cdnData={cdnData}
            field={field}
            relationKey={rowValue}
            size={"50"}
          />
        </td>
      );
    case "Record":
    case "dropdown-embed":
    case "Many":
    case "Map":
    case "Tuple":
    case "ListSet":
      return <td><ListViewer field={field} data={rowValue} /> </td>;
    case "Relation":
      if (fieldCardinalityMany(field)) {
        return <td><ListViewer field={field} data={JSON.stringify(rowValue)} /></td>;
      }
      return <td>{rowValue}</td>;
    case "Bool":
      return <td>{rowValue ? "True" : "False"}</td>
    default:
      // console.log(formType);
      // console.log(rowValue);
      return <td>{rowValue}</td>;
  }
};

interface EntityChildren {
  id: string;
  url: string;
  readOnly: boolean;
  e: Entity;
  cid: string;
}
const EntityChildren: React.FC<EntityChildren> = ({
  id,
  url,
  readOnly,
  e,
  cid,
}: EntityChildren): JSX.Element => {
  console.log(url);
  return !readOnly ? (
    <td>
      {" "}
      <Link to={"/" + url}>
        <button className="btn btn-primary mb-1">Load {entityName(e)}</button>
      </Link>
      <br />
    </td>
  ) : (
    //
    <td></td>
  );
};

interface TableActions {
  id: string;
  url: string;
  readOnly: boolean;
  e: Entity;
  cid: string;
}
const TableActions: React.FC<TableActions> = ({
  id,
  url,
  e,
  cid,
}: TableActions): JSX.Element => {
  const [deleteById, { isLoading, isError, error }] = useDeleteByIdMutation();
  const dispatch = useAppDispatch();
  const deleteBy = async (key: string) => {
    try {
      const response = await deleteById({
        e: e,
        cid: cid,
        keys: [key],
      });
      await dispatch(crudListApi.util.resetApiState());
      // handle successful deletion
    } catch (err) {
      console.error(err);
      // handle error
    }
  };
  const [showModal, setShowModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);

  const handleDeleteClick = (id: string) => {
    setItemToDelete(id);
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    if (itemToDelete) {
      deleteBy(itemToDelete);
      setShowModal(false);
      setItemToDelete(null);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setItemToDelete(null);
  };


  return !readOnlyEntity(e) ? (
    <td>
      {" "}
      <Link to={url}>
        <button className="btn btn-warning btn-sm mb-1">Edit</button>
      </Link>
      <br />
      {isLoading ? (
        <button className="mb-1" disabled>
          <div className="spinner-border text-info" role="status">
            <span className="sr-only"></span>
          </div>
          Deleting...
        </button>
      ) : (
        <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(id)}>
          Delete
        </button>
      )}
      {showModal && (
        <div>
        <div
          className="modal fade show d-sm-block mt-5"
          id="staticBackdrop"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Confirm Deletion
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleCancel}></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this record?</p>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleCancel}>
                  No
                </button>
                <button className="btn btn-danger" onClick={handleConfirmDelete}>
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
         
        </div>
         <div className="modal-backdrop fade show"></div>
        </div>
        
      )}
    </td>
  ) : (
    //
    <td></td>
  );
};

interface TablePagination {
  totalPages: number;
  currentPage: number;
  total: number;
  pageClicked: (page: number) => void;
}
const TablePagination = (props: TablePagination) => {
  const { totalPages, currentPage, pageClicked, total } = props;

  const createPaginationItem = (i: number) => {
    return (
      <li key={i} className={`page-item ${i === currentPage ? "active" : ""}`}>
        <a
          className="page-link"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            pageClicked(i);
          }}
        >
          {i}
        </a>
      </li>
    );
  };

  const paginationItems = [];

  let startPage = Math.max(2, currentPage - 1);
  let endPage = Math.min(totalPages - 1, currentPage + 1);

  if (totalPages <= 4) {
    startPage = 2;
    endPage = totalPages - 1;
  }

  paginationItems.push(createPaginationItem(1));

  if (startPage > 2) {
    paginationItems.push(
      <li key="ellipsis1" className="page-item disabled">
        <span className="page-link">...</span>
      </li>
    );
  }

  for (let i = startPage; i <= endPage && i > 1; i++) {
    paginationItems.push(createPaginationItem(i));
  }

  if (endPage < totalPages - 1) {
    paginationItems.push(
      <li key="ellipsis2" className="page-item disabled">
        <span className="page-link">...</span>
      </li>
    );
  }

  if (totalPages > 1) {
    paginationItems.push(createPaginationItem(totalPages));
  }

  return (
    <div style={{ marginTop: "5px", marginBottom: "70px" }}>
      <nav aria-label="Page navigation">
        <ul className="pagination paginationInfo">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <a
              className="page-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                pageClicked(Math.max(1, currentPage - 1));
              }}
            >
              Previous
            </a>
          </li>
          {paginationItems}
          <li
            className={`page-item ${currentPage === totalPages ? "disabled" : ""
              }`}
          >
            <a
              className="page-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                pageClicked(Math.min(totalPages, currentPage + 1));
              }}
            >
              Next
            </a>
          </li>
          <li className={`page-item disabled`}>
            <a className="page-link" href="#">
              Total: {total}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};
