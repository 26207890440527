import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Backend, createAuthClient } from '../services/api/backend';
import { getAdminPermission } from '../components/crud/Base';
import { Schema } from '../components/crud/Schema';

export interface UserState {
  principal: string;
  permissions: string;
}

export interface RoleParam {
  cid: string;
  schema: Schema;
}

// Define your API service
export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }), // Adjust the baseUrl according to your setup
  endpoints: (builder) => ({
    getPrincipalAndRole: builder.query<UserState, RoleParam>({
      queryFn: async (params: RoleParam) => {
        try {
          const authClient = await createAuthClient();  
          const identity = await authClient.getIdentity();
          const whoami = await identity.getPrincipal();
          // console.log(params);
          const permission = getAdminPermission(params.schema);
          if (!permission) {
            throw new Error('No permission found');
          }
          const pfa = await Backend.loadActorByName('User', params.cid);
          const apiPerms: any = await pfa.guard_permission(whoami, permission);
          console.log(apiPerms);
          if (apiPerms.Err) {
            return { error: { status: 'CUSTOM_ERROR', error: ` ${whoami.toString()} has no permissions. Please ask one of the admins to add your principal.` } };
          }
          return { data: { principal: whoami.toString(), permissions: 'admin' } };
        } catch (error) {
          if (error instanceof Error) {
            return { error: { status: 'FETCH_ERROR', error: error.message }};
          }
          return { error: { status: 'FETCH_ERROR', error: 'An unknown error occurred' }};
        }
      },
    }),
  }),
});

export const { useGetPrincipalAndRoleQuery } = userApi;


