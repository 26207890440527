export const idlFactory = ({ IDL }) => {
  const AppMode = IDL.Variant({
    'Enabled' : IDL.Null,
    'Disabled' : IDL.Null,
    'Readonly' : IDL.Null,
  });
  const AppState = IDL.Record({ 'mode' : AppMode });
  const Result = IDL.Variant({
    'Ok' : IDL.Null,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const FromUserRecord = IDL.Record({ 'user_id' : IDL.Principal });
  const FromCanisterRecord = IDL.Record({
    'canister_version' : IDL.Opt(IDL.Nat64),
    'canister_id' : IDL.Principal,
  });
  const CanisterChangeOrigin = IDL.Variant({
    'from_user' : FromUserRecord,
    'from_canister' : FromCanisterRecord,
  });
  const CreationRecord = IDL.Record({ 'controllers' : IDL.Vec(IDL.Principal) });
  const CodeDeploymentMode = IDL.Variant({
    'reinstall' : IDL.Null,
    'upgrade' : IDL.Null,
    'install' : IDL.Null,
  });
  const CodeDeploymentRecord = IDL.Record({
    'mode' : CodeDeploymentMode,
    'module_hash' : IDL.Vec(IDL.Nat8),
  });
  const LoadSnapshotRecord = IDL.Record({
    'canister_version' : IDL.Nat64,
    'taken_at_timestamp' : IDL.Nat64,
    'snapshot_id' : IDL.Vec(IDL.Nat8),
  });
  const CanisterChangeDetails = IDL.Variant({
    'creation' : CreationRecord,
    'code_deployment' : CodeDeploymentRecord,
    'load_snapshot' : LoadSnapshotRecord,
    'controllers_change' : CreationRecord,
    'code_uninstall' : IDL.Null,
  });
  const CanisterChange = IDL.Record({
    'timestamp_nanos' : IDL.Nat64,
    'canister_version' : IDL.Nat64,
    'origin' : CanisterChangeOrigin,
    'details' : CanisterChangeDetails,
  });
  const CanisterInfoResponse = IDL.Record({
    'controllers' : IDL.Vec(IDL.Principal),
    'module_hash' : IDL.Opt(IDL.Vec(IDL.Nat8)),
    'recent_changes' : IDL.Vec(CanisterChange),
    'total_num_changes' : IDL.Nat64,
  });
  const Result_1 = IDL.Variant({
    'Ok' : IDL.Text,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const CanisterState = IDL.Record({
    'root_id' : IDL.Opt(IDL.Principal),
    'path' : IDL.Opt(IDL.Text),
    'parent_id' : IDL.Opt(IDL.Principal),
  });
  const Model = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'part_ids' : IDL.Vec(IDL.Text),
    'slot_layout_id' : IDL.Text,
    'asset_model_id' : IDL.Text,
  });
  const Section = IDL.Variant({
    'Chin' : IDL.Null,
    'Eyebrows' : IDL.Null,
    'Ears' : IDL.Null,
    'Eyes' : IDL.Null,
    'Face' : IDL.Null,
    'Hair' : IDL.Null,
    'Underwear' : IDL.Null,
    'Lips' : IDL.Null,
    'Nose' : IDL.Null,
    'Skin' : IDL.Null,
    'Eyelashes' : IDL.Null,
    'Unspecified' : IDL.Null,
  });
  const Part = IDL.Record({
    'id' : IDL.Text,
    'section' : Section,
    'ident' : IDL.Text,
    'customizer_ids' : IDL.Vec(IDL.Text),
    'mesh_ids' : IDL.Vec(IDL.Text),
  });
  const Live = IDL.Record({ 'is_live' : IDL.Bool });
  const RegenSpeed = IDL.Variant({
    'VeryFast' : IDL.Null,
    'VerySlow' : IDL.Null,
    'Fast' : IDL.Null,
    'Average' : IDL.Null,
    'Slow' : IDL.Null,
  });
  const Resource = IDL.Record({
    'regen_speed' : IDL.Opt(RegenSpeed),
    'volume' : IDL.Opt(IDL.Float64),
    'resource_id' : IDL.Text,
  });
  const Slot = IDL.Variant({
    'Ears' : IDL.Null,
    'Eyes' : IDL.Null,
    'Face' : IDL.Null,
    'Feet' : IDL.Null,
    'Hair' : IDL.Null,
    'Hand' : IDL.Null,
    'Head' : IDL.Null,
    'Legs' : IDL.Null,
    'Nose' : IDL.Null,
    'Tail' : IDL.Null,
    'Waist' : IDL.Null,
    'Wrist' : IDL.Null,
    'Mouth' : IDL.Null,
    'Finger' : IDL.Null,
    'Chest' : IDL.Null,
    'Gloves' : IDL.Null,
    'Unspecified' : IDL.Null,
  });
  const Span = IDL.Variant({
    'One' : IDL.Null,
    'Two' : IDL.Null,
    'Four' : IDL.Null,
  });
  const Dominance = IDL.Variant({
    'Secondary' : IDL.Null,
    'Primary' : IDL.Null,
  });
  const CarryMode = IDL.Variant({
    'TwoHandRanged' : IDL.Null,
    'TwoHandMelee' : IDL.Null,
    'OneHandRanged' : IDL.Null,
    'OneHandShield' : IDL.Null,
    'OneHandMelee' : IDL.Null,
  });
  const EquipTarget = IDL.Record({
    'slot' : Slot,
    'span' : Span,
    'dominance' : IDL.Opt(Dominance),
    'carry_mode' : IDL.Opt(CarryMode),
  });
  const Insulation = IDL.Variant({
    'Light' : IDL.Null,
    'High' : IDL.Null,
    'None' : IDL.Null,
    'Normal' : IDL.Null,
  });
  const ModifierGroup = IDL.Record({
    'movement' : IDL.Opt(IDL.Int8),
    'carry' : IDL.Opt(IDL.Int8),
    'armour' : IDL.Opt(IDL.Int8),
    'warmth' : IDL.Opt(IDL.Int8),
    'health' : IDL.Opt(IDL.Int8),
  });
  const Equipment = IDL.Record({
    'equip_target' : EquipTarget,
    'is_boe' : IDL.Bool,
    'insulation' : Insulation,
    'stat_modifiers' : IDL.Opt(ModifierGroup),
    'asset_model_id' : IDL.Opt(IDL.Text),
  });
  const Food = IDL.Record({
    'effect' : IDL.Opt(IDL.Bool),
    'satiation' : IDL.Nat8,
    'waste_item_id' : IDL.Opt(IDL.Text),
  });
  const Grid = IDL.Record({
    'height' : IDL.Nat8,
    'width' : IDL.Nat8,
    'bitmap' : IDL.Opt(IDL.Vec(IDL.Nat8)),
  });
  const Storage = IDL.Record({ 'grid' : Grid });
  const Shop = IDL.Record({ 'shop_id' : IDL.Text });
  const Spell = IDL.Record({ 'spell_id' : IDL.Text });
  const Examine = IDL.Record({ 'examine_text_id' : IDL.Text });
  const Spawner = IDL.Record({ 'spawner_id' : IDL.Text });
  const RegenSpeed_1 = IDL.Variant({
    'VeryFast' : IDL.Null,
    'VerySlow' : IDL.Null,
    'Fast' : IDL.Null,
    'Average' : IDL.Null,
    'None' : IDL.Null,
    'Slow' : IDL.Null,
  });
  const Dice = IDL.Record({
    'multiplier' : IDL.Int8,
    'modifier' : IDL.Int8,
    'drop_lowest' : IDL.Nat8,
    'count' : IDL.Nat8,
    'drop_highest' : IDL.Nat8,
    'faces' : IDL.Nat8,
  });
  const Harvest = IDL.Record({
    'max_harvests' : IDL.Nat8,
    'regen_speed' : RegenSpeed_1,
    'harvest_quantity' : IDL.Opt(Dice),
    'item_id' : IDL.Text,
  });
  const Matter = IDL.Variant({
    'Gas' : IDL.Null,
    'Solid' : IDL.Null,
    'Liquid' : IDL.Null,
  });
  const Container = IDL.Record({ 'matter' : Matter, 'capacity' : IDL.Float64 });
  const Cooldown = IDL.Variant({
    'Fast' : IDL.Null,
    'Slow' : IDL.Null,
    'Unspecified' : IDL.Null,
    'SuperFast' : IDL.Null,
    'SuperSlow' : IDL.Null,
  });
  const Difficulty = IDL.Variant({
    'VeryEasy' : IDL.Null,
    'VeryHard' : IDL.Null,
    'Easy' : IDL.Null,
    'Hard' : IDL.Null,
    'Unspecified' : IDL.Null,
  });
  const Modifier = IDL.Variant({
    'CraftTime' : Cooldown,
    'Difficulty' : Difficulty,
    'Cooldown' : Cooldown,
    'Unspecified' : IDL.Null,
  });
  const SkillEntry = IDL.Record({ 'tier' : IDL.Nat8, 'skill_id' : IDL.Text });
  const Crafter = IDL.Record({
    'modifiers' : IDL.Vec(Modifier),
    'skills' : IDL.Vec(SkillEntry),
  });
  const Converter = IDL.Record({
    'output_grid' : Grid,
    'input_grid' : Grid,
    'rules' : IDL.Bool,
  });
  const Drink = IDL.Record({ 'effect' : IDL.Opt(IDL.Bool) });
  const Consumable = IDL.Record({
    'effect' : IDL.Opt(IDL.Bool),
    'waste_item_id' : IDL.Opt(IDL.Text),
  });
  const Hatchable = IDL.Record({ 'draggin_hatch_id' : IDL.Text });
  const Smashable = IDL.Record({ 'loot_id' : IDL.Text });
  const Recipe = IDL.Record({ 'recipe_id' : IDL.Text });
  const Attributes = IDL.Record({
    'resource' : IDL.Opt(Resource),
    'equipment' : IDL.Opt(Equipment),
    'food' : IDL.Opt(Food),
    'storage' : IDL.Opt(Storage),
    'nest' : IDL.Opt(IDL.Record({})),
    'seed' : IDL.Opt(IDL.Record({})),
    'shop' : IDL.Opt(Shop),
    'spell' : IDL.Opt(Spell),
    'planter' : IDL.Opt(IDL.Record({})),
    'examine' : IDL.Opt(Examine),
    'spawner' : IDL.Opt(Spawner),
    'harvest' : IDL.Opt(Harvest),
    'container' : IDL.Opt(Container),
    'crafter' : IDL.Opt(Crafter),
    'converter' : IDL.Opt(Converter),
    'drink' : IDL.Opt(Drink),
    'consumable' : IDL.Opt(Consumable),
    'hatchable' : IDL.Opt(Hatchable),
    'smashable' : IDL.Opt(Smashable),
    'recipe' : IDL.Opt(Recipe),
  });
  const Item = IDL.Record({
    'id' : IDL.Text,
    'can_trade' : IDL.Bool,
    'rarity_id' : IDL.Text,
    'live' : Live,
    'name' : IDL.Text,
    'asset_icon_id' : IDL.Text,
    'description' : IDL.Opt(IDL.Text),
    'stack' : IDL.Nat16,
    'attributes' : Attributes,
    'asset_model_id' : IDL.Text,
    'can_sell' : IDL.Bool,
    'category_id' : IDL.Text,
  });
  const Type = IDL.Variant({
    'Sound' : IDL.Null,
    'Image' : IDL.Null,
    'Model' : IDL.Null,
    'Theme' : IDL.Null,
    'Thumb' : IDL.Null,
    'Material' : IDL.Null,
    'Unspecified' : IDL.Null,
  });
  const Asset = IDL.Record({ 'ty' : Type, 'path' : IDL.Text });
  const Value = IDL.Variant({ 'Number' : IDL.Float64, 'Color' : IDL.Text });
  const Modifier_1 = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'assets' : IDL.Vec(Asset),
    'parameters' : IDL.Vec(IDL.Tuple(IDL.Text, Value)),
  });
  const Customizer = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'part_modifier_ids' : IDL.Vec(IDL.Text),
  });
  const Type_1 = IDL.Variant({
    'Secondary' : IDL.Null,
    'Primary' : IDL.Null,
    'Keeper' : IDL.Null,
    'Backstory' : IDL.Null,
    'Special' : IDL.Null,
    'Unspecified' : IDL.Null,
  });
  const Source = IDL.Variant({
    'Energy' : IDL.Null,
    'Shadow' : IDL.Null,
    'Lifeblood' : IDL.Null,
    'Unspecified' : IDL.Null,
    'Earth' : IDL.Null,
  });
  const Flow = IDL.Record({
    'source' : IDL.Opt(Source),
    'quantity' : IDL.Int32,
  });
  const Species = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'asset_icon_id' : IDL.Text,
    'description' : IDL.Text,
  });
  const Skill = IDL.Record({ 'rank' : IDL.Nat8, 'skill' : Species });
  const Ability = IDL.Record({ 'ability_id' : IDL.Text });
  const Set = IDL.Record({
    'pet_limit' : IDL.Opt(IDL.Nat8),
    'flow' : IDL.Opt(Flow),
    'spell_limit' : IDL.Opt(IDL.Nat8),
    'skill' : IDL.Opt(Skill),
    'ability' : IDL.Opt(Ability),
  });
  const Role = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'rank' : IDL.Nat8,
    'description' : IDL.Text,
    'name_feminine' : IDL.Opt(IDL.Text),
    'role_type' : Type_1,
    'modifiers' : Set,
    'category_id' : IDL.Text,
  });
  const Choice = IDL.Record({
    'id' : IDL.Text,
    'backstory_id' : IDL.Text,
    'stat_modifiers' : ModifierGroup,
    'gender_id' : IDL.Text,
    'species_id' : IDL.Text,
    'model_id' : IDL.Text,
  });
  const Model_1 = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'assets' : IDL.Vec(Asset),
    'description' : IDL.Text,
    'material_ids' : IDL.Vec(IDL.Text),
  });
  const Backstory = IDL.Record({
    'id' : IDL.Text,
    'role_ids' : IDL.Vec(IDL.Text),
    'recipe_ids' : IDL.Vec(IDL.Text),
    'name' : IDL.Text,
    'description' : IDL.Text,
    'ability_ids' : IDL.Vec(IDL.Text),
    'item_equipment_ids' : IDL.Vec(IDL.Text),
    'currency' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Nat32)),
    'items' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Nat16)),
    'starting_zone_id' : IDL.Text,
  });
  const Mesh = IDL.Record({
    'id' : IDL.Text,
    'asset_mesh_id' : IDL.Text,
    'live' : Live,
    'name' : IDL.Text,
  });
  const Rarity = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'color' : IDL.Text,
    'asset_icon_id' : IDL.Text,
    'description' : IDL.Text,
    'level' : IDL.Nat8,
  });
  const Parameter = IDL.Record({ 'id' : IDL.Text, 'ident' : IDL.Text });
  const CharacterCreator = IDL.Record({
    'model' : IDL.Vec(Model),
    'model_part' : IDL.Vec(Part),
    'item' : IDL.Vec(Item),
    'model_part_modifier' : IDL.Vec(Modifier_1),
    'model_part_customizer' : IDL.Vec(Customizer),
    'role' : IDL.Vec(Role),
    'creator_choice' : IDL.Vec(Choice),
    'asset_model' : IDL.Vec(Model_1),
    'creator_backstory' : IDL.Vec(Backstory),
    'model_part_mesh' : IDL.Vec(Mesh),
    'rarity' : IDL.Vec(Rarity),
    'model_part_parameter' : IDL.Vec(Parameter),
    'species' : IDL.Vec(Species),
  });
  const Result_2 = IDL.Variant({
    'Ok' : CharacterCreator,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const DeleteRequest = IDL.Record({
    'key' : IDL.Vec(IDL.Text),
    'entity' : IDL.Text,
  });
  const DeleteResponse = IDL.Record({
    'keys' : IDL.Vec(IDL.Vec(IDL.Tuple(IDL.Text, IDL.Opt(IDL.Text)))),
  });
  const Result_3 = IDL.Variant({
    'Ok' : DeleteResponse,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const LoadMethod = IDL.Variant({
    'All' : IDL.Null,
    'One' : IDL.Vec(IDL.Text),
    'Many' : IDL.Vec(IDL.Vec(IDL.Text)),
    'Only' : IDL.Null,
    'Range' : IDL.Tuple(IDL.Vec(IDL.Text), IDL.Vec(IDL.Text)),
    'Prefix' : IDL.Vec(IDL.Text),
  });
  const SortDirection = IDL.Variant({ 'Asc' : IDL.Null, 'Desc' : IDL.Null });
  const Filter = IDL.Variant({
    'All' : IDL.Text,
    'Fields' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
  });
  const LoadFormat = IDL.Variant({ 'Rows' : IDL.Null, 'Count' : IDL.Null });
  const LoadRequest = IDL.Record({
    'entity' : IDL.Text,
    'method' : LoadMethod,
    'order' : IDL.Opt(IDL.Vec(IDL.Tuple(IDL.Text, SortDirection))),
    'offset' : IDL.Nat32,
    'limit' : IDL.Opt(IDL.Nat32),
    'filter' : IDL.Opt(Filter),
    'format' : LoadFormat,
  });
  const Metadata = IDL.Record({
    'created' : IDL.Nat64,
    'modified' : IDL.Nat64,
  });
  const QueryValue = IDL.Record({
    'metadata' : Metadata,
    'data' : IDL.Vec(IDL.Nat8),
  });
  const QueryRow = IDL.Record({
    'key' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Opt(IDL.Text))),
    'value' : QueryValue,
  });
  const LoadResponse = IDL.Variant({
    'Rows' : IDL.Vec(QueryRow),
    'Count' : IDL.Nat32,
  });
  const Result_4 = IDL.Variant({
    'Ok' : LoadResponse,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const SaveRequestAction = IDL.Variant({
    'Create' : IDL.Null,
    'Update' : IDL.Null,
  });
  const SaveRequest = IDL.Record({
    'entity' : IDL.Text,
    'action' : SaveRequestAction,
    'data' : IDL.Vec(IDL.Nat8),
  });
  const CreateResponse = IDL.Record({ 'row' : QueryRow });
  const SaveResponse = IDL.Variant({
    'Create' : CreateResponse,
    'Update' : CreateResponse,
  });
  const Result_5 = IDL.Variant({
    'Ok' : SaveResponse,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Derive = IDL.Record({ 'price' : IDL.Nat64 });
  const Result_6 = IDL.Variant({
    'Ok' : IDL.Vec(IDL.Tuple(Item, Derive)),
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Result_7 = IDL.Variant({
    'Ok' : IDL.Vec(Rarity),
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Value_1 = IDL.Variant({
    'Int' : IDL.Int64,
    'Bool' : IDL.Bool,
    'String' : IDL.Text,
    'Decimal' : IDL.Float64,
  });
  const Feature = IDL.Record({
    'key' : IDL.Text,
    'values' : IDL.Vec(IDL.Tuple(IDL.Text, Value_1)),
  });
  const Result_8 = IDL.Variant({
    'Ok' : IDL.Vec(Feature),
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Result_9 = IDL.Variant({
    'Ok' : IDL.Vec(IDL.Text),
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  const Kind = IDL.Variant({
    'Storage' : Storage,
    'Nest' : IDL.Record({}),
    'Planter' : IDL.Record({}),
    'Harvest' : Harvest,
    'Scenery' : IDL.Null,
    'Converter' : Converter,
    'Crafter' : Crafter,
    'Smashable' : Smashable,
    'Unspecified' : IDL.Null,
    'Resource' : Resource,
  });
  const LightSource = IDL.Record({ 'luminosity' : IDL.Int32 });
  const Collider = IDL.Record({ 'is_intangible' : IDL.Bool });
  const Properties = IDL.Record({
    'light_source' : IDL.Opt(LightSource),
    'collider' : IDL.Opt(Collider),
  });
  const Attributes_1 = IDL.Record({
    'shop' : IDL.Opt(Shop),
    'examine' : IDL.Opt(Examine),
  });
  const Placement = IDL.Record({
    'is_wall' : IDL.Bool,
    'is_ceiling' : IDL.Bool,
    'is_floor' : IDL.Bool,
  });
  const ScalingRange = IDL.Variant({
    'MuchBigger' : IDL.Null,
    'NoChange' : IDL.Null,
    'MuchSmaller' : IDL.Null,
  });
  const BuilderOptions = IDL.Record({
    'placement' : Placement,
    'scaling_range' : ScalingRange,
  });
  const Prop = IDL.Record({
    'id' : IDL.Text,
    'kind' : Kind,
    'live' : Live,
    'name' : IDL.Text,
    'description' : IDL.Text,
    'properties' : IDL.Opt(Properties),
    'attributes' : IDL.Opt(Attributes_1),
    'builder_options' : BuilderOptions,
    'asset_model_id' : IDL.Text,
    'category_id' : IDL.Text,
    'setting_id' : IDL.Opt(IDL.Text),
  });
  const Block = IDL.Record({
    'x' : IDL.Nat32,
    'y' : IDL.Nat32,
    'z' : IDL.Nat32,
    'id' : IDL.Text,
    'live' : Live,
    'name' : IDL.Text,
    'description' : IDL.Opt(IDL.Text),
  });
  const Material = IDL.Record({
    'id' : IDL.Text,
    'live' : Live,
    'asset_material_id' : IDL.Text,
    'setting_id' : IDL.Opt(IDL.Text),
  });
  const WorldBuilder = IDL.Record({
    'prop' : IDL.Vec(Prop),
    'block' : IDL.Vec(Block),
    'material' : IDL.Vec(Material),
  });
  const Result_10 = IDL.Variant({
    'Ok' : WorldBuilder,
    'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
  });
  return IDL.Service({
    'app_state' : IDL.Func([], [AppState], ['query']),
    'app_state_cascade' : IDL.Func([AppState], [Result], []),
    'canister_caller' : IDL.Func([], [IDL.Principal], ['query']),
    'canister_id' : IDL.Func([], [IDL.Principal], ['query']),
    'canister_info' : IDL.Func([], [CanisterInfoResponse], []),
    'canister_path' : IDL.Func([], [Result_1], ['query']),
    'canister_state' : IDL.Func([], [CanisterState], ['query']),
    'canister_time' : IDL.Func([], [IDL.Nat64], ['query']),
    'canister_upgrade_children' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [Result],
        [],
      ),
    'canister_version' : IDL.Func([], [IDL.Nat64], ['query']),
    'character_creator_manifest' : IDL.Func([], [Result_2], ['query']),
    'child_index' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Text))],
        ['query'],
      ),
    'crud_delete' : IDL.Func([DeleteRequest], [Result_3], []),
    'crud_load' : IDL.Func([LoadRequest], [Result_4], ['composite_query']),
    'crud_save' : IDL.Func([SaveRequest], [Result_5], []),
    'db_load' : IDL.Func([], [Result], ['query']),
    'fixtures_replace_all' : IDL.Func([], [Result], []),
    'ic_cycles_accept' : IDL.Func([IDL.Nat64], [IDL.Nat64], []),
    'init_async' : IDL.Func([], [Result], []),
    'items' : IDL.Func([], [Result_6], ['query']),
    'rarities' : IDL.Func([], [Result_7], ['query']),
    'rarities_query' : IDL.Func(
        [
          IDL.Text,
          IDL.Vec(IDL.Tuple(IDL.Text, SortDirection)),
          IDL.Nat32,
          IDL.Opt(IDL.Nat32),
        ],
        [Result_7],
        ['query'],
      ),
    'registry' : IDL.Func([], [Result_8], ['query']),
    'store_clear' : IDL.Func([IDL.Text], [Result], []),
    'store_keys' : IDL.Func([IDL.Text], [Result_9], ['composite_query']),
    'subnet_index' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Principal))],
        ['query'],
      ),
    'subnet_index_cascade' : IDL.Func(
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Principal))],
        [Result],
        [],
      ),
    'world_builder_manifest' : IDL.Func([], [Result_10], ['query']),
  });
};
export const init = ({ IDL }) => { return [IDL.Principal, IDL.Principal]; };
