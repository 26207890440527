import { memo, useContext, useEffect, useState } from "react";
import { CONTEXT_UPDATE, constructFieldFromType, fieldCardinalityMany, fieldCardinalityOpt,
   fieldName, fieldRelation, fieldType, getStateNameExtra, 
   getTypeData} from "../crud/Base";
import { Enum, EnumVariant, Field } from "../crud/Schema";
import { loadState } from "../../reducers/schemaSlice";
import { useAppDispatch, useAppSelector } from "../../services/hooks";
import { deleteByKey, filterAndUpdate, formState, setFormValue } from "../../reducers/formSlice";
import Select from 'react-select';
import FormElement from "./form.element";
import { Nullable } from "./nullable";
import { EnvironmentContext } from "../../..";

export interface MappedEnum {
  label: string;
  value: string;
  type: null | string;
}
export const getOptions = (newType: Enum): MappedEnum[] => {
  const variants = variantsMap(newType.variants);
  // console.log(newType.variants);
  const options = variants.map((variant: EnumVariant) => {
    if (!variant.value) {
      return { value: variant.name, label: variant.name, type: null };
    }
    const relationType = fieldRelation(variant as Field);
    // console.log(relationType);
    const variantType = variant.value.item.is;
    if (!variantType && !relationType) {
      throw new Error(`Variant ${variant.name} has no type!`);
    }
    return { value: variant.name, label: variant.name, type: variantType ? variantType : (relationType !== undefined ? relationType : null) };
  });
  return options;
};

export const getOriginalOption = (options: EnumVariant[], selected: MappedEnum): Field => {
  const option = options.find((option: EnumVariant) => {
    return (option.name === selected.value);
  });
  if (!option || option.value === undefined) {
    throw new Error(`Variant ${selected.label} not found!`);
  }
  // console.log(constructFieldFromType(option.value.item, option.value.cardinality, option.name));
  return constructFieldFromType(option.value.item, option.value.cardinality, option.name);
};

export const variantsMap = (options: EnumVariant[]): EnumVariant[] => {
  return options.filter((option: EnumVariant) => !option.unspecified);
};


interface VariantElement {
  field: Field;
  parentRef?: Enum;
  width: number;
  extras: string;
  data: any;
};

const VariantElement: React.FC<VariantElement> = (props: VariantElement) => {
  const [value, setValue] = useState<any>([]);
  const [refresh, setRefresh] = useState(false);
  const [nullValue, setNullValue] = useState(true);
  const { field, extras, data } = props;
  const schema = loadState();
  const parentRef = (getTypeData(schema, fieldType(field)) as Enum);
  const options = getOptions(parentRef);
  // console.log(options);
  const originalOptions = variantsMap(parentRef.variants);

  const dispatch = useAppDispatch();

  const { form, context } = useAppSelector(formState);

  const multi = fieldCardinalityMany(field);
  useEffect(() => {
    const firstOption: MappedEnum = options[0];
    // console.log(firstOption);
    setValue(firstOption);
    const nullable = fieldCardinalityOpt(field);
    if (nullable) {
      setNullValue(false);
    }
    // console.log(data);
    if (context === CONTEXT_UPDATE && data && data.length > 0) {
      const stateName = getStateNameExtra(field, extras);
      // console.log(data);
      if (multi) {
        console.log(field);
        console.log(stateName);
        const manyKeys: any = [];
        for (let val of data) {
          const breakWord = val.key.split(stateName + '-');
          const getRest = breakWord[1];
          console.log(getRest);
          if (getRest && getRest.length > 0) {
            const objOrArr = getRest.split('-');
            // console.log(objOrArr[0]);
            if (objOrArr[0] && objOrArr[0].includes('array_key')) {
              let keyArr = objOrArr[0].split('array_key_')[1];
              // console.log(keyArr);
              if (keyArr !== undefined && !manyKeys.includes(Number(keyArr))) {
                manyKeys.push(Number(keyArr));
              }
            }
          }
        }
      }
      const selectedOptionKey = data[0].key.split(stateName + '-')[1];
      const selectedOptionLabel = selectedOptionKey.split('-')[0];
      // console.log(selectedOptionLabel);
      const selectOption = options.find((option: any) => option.label === selectedOptionLabel);
      if (value && value.value !== selectOption?.value) {
        setValue(selectOption);
        setNullValue(true);
        if (selectOption?.type === null) {
          // console.log(selectOption);
          dispatch(setFormValue({
            key: fieldCardinalityOpt(field) ? getStateNameExtra(field, extras) : 
            getStateNameExtra(field, extras) + `-${selectOption.label}`,
            value: selectOption.value,
            extras: getStateNameExtra(field, extras),
            error: []
          }));
        }
      }

      if (data.length === 1) {
        const getValue = data[0].value;
        if (getValue !== null) {
          setNullValue(true);
          // setFetched(true);
          // return;
        }
        return;
      }

    } else {
       // first option is a non value so it needs to be injected as value
      if (firstOption.type === null) {
        dispatch(setFormValue({
          key: fieldCardinalityOpt(field) ? getStateNameExtra(field, extras) : getStateNameExtra(field, extras) + `-${firstOption.label}`,
          value: nullable ? null : firstOption.value,
          extras: getStateNameExtra(field, extras),
          error: []
        }));
      }
    }
  }, []);

  const nullableClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    const target = e.target as HTMLInputElement;
    const checked: boolean = target.checked;
    setNullValue(checked);
    if (nullValue) {
      const extraData = extras + `${fieldName(field)}-`;
      // console.log(extraData);
      dispatch(filterAndUpdate(extraData));
      dispatch(setFormValue({
        key: getStateNameExtra(field, extras),
        value: null,
        extras: getStateNameExtra(field, extras),
        error: []
      }));
      setValue([]);
    } else {
      console.log(value);
      const firstOption = options[0];
      console.log(firstOption);
      // simple value so need to dispatch 
      if (firstOption.type === null) {
        if (fieldCardinalityOpt(field)) {
          dispatch(deleteByKey(getStateNameExtra(field, extras)));
        }
        dispatch(setFormValue({
          key: getStateNameExtra(field, extras) + `-${firstOption.label}`,
          value: firstOption.value,
          extras: getStateNameExtra(field, extras),
          error: []
        }));
      } else {
        // delete the key cause it's embed and they'll be added automatically
        dispatch(deleteByKey(getStateNameExtra(field, extras)));
      }
    }
  };

  const handleChange = (selectedOption: any) => {
    const valueSelect = selectedOption === null ? null : selectedOption.value;
    setValue(selectedOption);
    // previous data
    const extraData = getStateNameExtra(field, extras) + '-' + value.label;
    // console.log(extraData);
    dispatch(filterAndUpdate(extraData));
    setRefresh(!refresh);
    // console.log(selectedOption);
    if (selectedOption.type === null) {
      console.log(`${getStateNameExtra(field, extras)}-${fieldName(field)}`);
      dispatch(setFormValue({
        key: getStateNameExtra(field, extras) + '-' + selectedOption.label,
        value: valueSelect,
        extras: getStateNameExtra(field, extras),
        error: []
      }));
    } else {
      setRefresh(!refresh);
      dispatch(deleteByKey(getStateNameExtra(field, extras)));
    }
  };
  // console.log(field);
  // console.log(options);
  // console.log(value);
  // console.log(getStateNameExtra(field, extras));
  return <div className="row g-1">
    <div className="col-md-1">
      <label className="text-break">
        <small><strong>{fieldName(field)}</strong></small>
      </label>
    </div>
    {fieldCardinalityOpt(field) && (
      <div className="col-md-1 d-flex justify-content-center">
        <Nullable field={field} checked={nullValue} onChange={nullableClick} />
      </div>
    )}
    <div className="col-md-10">
      <div className="row">
        <div className="form-group">
          {nullValue === true && (
            <div className="container border rounded mb-2 mt-2">
              <Select
                value={value}
                options={options}
                onChange={handleChange}
              />
              {(value.value && value.type !== null) && (
                <FormElement
                  key={value.type}
                  data={data}
                  field={getOriginalOption(originalOptions, value)}
                  extras={extras + (value.type.type === 'Primitive' ? `${fieldName(field)}-` : `${fieldName(field)}-`)}
                  width={8}
                />
              )}
              <div className="invalid-feedback">
                Field Required
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
};

export default memo(VariantElement);