import React, { useState } from 'react';
import { Field } from '../crud/Schema';
import { fieldName } from '../crud/Base';
import { formTypeValue } from '../ui/form.element';
import { Filter as CrudFilter } from '../../../declarations/config/config.did.t';

export type Filter = CrudFilter;

interface InternalFilter {
  name: string;
  value: string | number;
}

interface FiltersProps {
  fields: Field[];
  onApplyFilters: (filters: Filter) => void;
}


export const Filters = ({ fields, onApplyFilters }: FiltersProps) => {
  const [filters, setFilters] = useState<InternalFilter[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);

  const updateFilter = (fieldName: string, value: any) => {
    setFilters((prevFilters) => {
      const existingIndex = prevFilters.findIndex(filter => filter.name === fieldName);
      if (existingIndex !== -1) {
        return prevFilters.map((filter, index) => {
          if (index === existingIndex) {
            return { ...filter, value: value };
          }
          return filter;
        });
      } else {
        return [...prevFilters, { name: fieldName, value: value }];
      }
    });
  }

  const getValue = (fieldName: string) => {
    const filter = filters.find(filter => filter.name === fieldName);
    return filter ? filter.value : '';
  };
  
  const handleInputChange = (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFilter(fieldName, event.target.value);
  };
  
  const clearInput = (fieldName: string) => () => {
    updateFilter(fieldName, '');
  };

  const mapFilters = (): Filter => {
    setDisabled(true);
    const filterArr: [string, string][] =  filters.map((filter) => {
      return [filter.name, String(filter.value)];
    })
    setTimeout(() => {
      setDisabled(false);
    }, 4000);
    // console.log(filterArr);
    return { 'Fields': filterArr };
  };

  return (
    <div className="container ml-0">
      <div className="row">
        {Object.values(fields).map((field) => {
          const { formType } = formTypeValue(field);
          if (formType === 'String' ||
             formType === 'Text' ||
              formType === 'Textarea' ||
              fieldName(field) === 'id') {
            return <div className='col-4 d-flex align-items-center'>
              <input
                className="form-control form-control-sm"
                type="text"
                value={getValue(fieldName(field))}
                onChange={handleInputChange(fieldName(field))}
                placeholder={fieldName(field)}
              />
              <span onClick={clearInput(fieldName(field))} className="form-control-clear bi bi-x-circle form-control-feedback hidden"></span>
            </div>
          } else if (formType === 'Number') {
            return <div className='col-4'>
              <input
                className="form-control form-control-sm"
                type="number"
                placeholder=".form-control-sm"
              />
            </div>
          }
        }
        )}
      </div>
      <button type="submit" onClick={() => onApplyFilters(mapFilters())} className="btn btn-info btn-sm mt-2" disabled={filters.length === 0 || disabled}>Apply Filters</button>

    </div>
  );
};