import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Backend } from '../services/api/backend';
import moment from 'moment';
import { toHexString } from '@dfinity/candid';
import { extractVariantName } from '../components/crud/Base';

export interface CanisterState {
  cid: string;
  name: string;
  module_hash?: string;
}

export type CdnCanisterState = CanisterState & { active: boolean };

export interface SnapShot {
  cid: string;
  name: string;
  memoryDataId: number;
};

export const canisterApi = createApi({
  reducerPath: 'canisterApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }), // Adjust the baseUrl according to your setup
  endpoints: (builder) => ({
    playerCanisters: builder.query<CanisterState[], string>({
      queryFn: async (userCid: string) => {
        try {
          const canisters: string | null = localStorage.getItem("player_canisters");
          if (canisters === null || (JSON.parse(canisters)).length === 0) {
            // console.log(userCid);
            const ba: Backend.ActorTypes["User"] = await Backend.loadActorByName('User', userCid);
            const playerCanisters: any = await ba.child_index();
            // console.log(playerCanisters);
            if (playerCanisters.Err) {
              return { error: { status: 'CUSTOM_ERROR', error: `can't fetch player canisters...` } };
            }
            // console.log(playerCanisters);
            // console.log(typeof playerCanisters);
            const newCanisters: CanisterState[] = [];
            for (let player of playerCanisters) {
              newCanisters.push({
                name: player[0].toString(),
                cid: player[0].toString()
              });
            }
            const data = JSON.stringify(newCanisters);
            localStorage.setItem("player_canisters", data);
            return { data: newCanisters };
          }

          return {data: JSON.parse(canisters)};             
        } catch (error) {
          if (error instanceof Error) {
            return { error: { status: 'FETCH_ERROR', error: error.message }};
          }
          return { error: { status: 'FETCH_ERROR', error: 'An unknown error occurred' }};
        }
      },
    }),
    mainCanisters: builder.query<CanisterState[], string>({
      queryFn: async (environment) => {
        try {
          const canisters: string | null = localStorage.getItem("main");
          if (canisters === null || (JSON.parse(canisters)).length === 0) {
            // console.log( Backend.getCanisterIdByName('Root', environment));
            const root: Backend.ActorTypes["Root"] = await Backend.loadActorByName('Root', Backend.getCanisterIdByName('Root', environment));
            const canister_id: any = await root.canister_id();
            // console.log(canister_id);
            // const balance: any = await root.canister_balance();
            // console.log(balance);
            const mainCanisters: any = await root.child_index();
            console.log(mainCanisters);
            if (mainCanisters.length === 0) {
              return { error: { status: 'CUSTOM_ERROR', error: `can't fetch main canisters...` } };
            }
            
            const newCanisters: CanisterState[] = [];
            for (let cids of mainCanisters) {
              // console.log(cids);
              newCanisters.push({
                name: cids[1],
                cid: cids[0].toString()
              });
            }
            const data = JSON.stringify(newCanisters);
            localStorage.setItem("main", data);
            return { data: newCanisters };
          }

          return {data: JSON.parse(canisters)};             
        } catch (error) {
          if (error instanceof Error) {
            return { error: { status: 'FETCH_ERROR', error: error.message }};
          }
          return { error: { status: 'FETCH_ERROR', error: 'An unknown error occurred' }};
        }
      },
    }),
    cdnCanisters: builder.query<CdnCanisterState[], string>({
      queryFn: async (environment) => {
        try {
          const canisters: string | null = localStorage.getItem("cdn_canisters");
          if (canisters === null || (JSON.parse(canisters)).length === 0) {
            const cc: Backend.ActorTypes["cdn_container"] = await Backend.loadActorByName('cdn_container', 
            Backend.getCanisterIdByName('cdn_container', environment));
            const buckets: any = await cc.list_buckets();
            
            if (!buckets) {
              return { error: { status: 'CUSTOM_ERROR', error: `can't fetch cdn canisters...` } };
            }
            const group: CdnCanisterState[] = [];
            for (let bc of buckets) {
              group.push({
                name:  `bucket - ${toHexString(bc.hash[0])}`,
                cid: bc.id.toString(),
                active: true,
              })
            }
            const data = JSON.stringify(group);
            localStorage.setItem("cdn_canisters", data);
            return { data: group };
          }

          return {data: JSON.parse(canisters)};             
        } catch (error) {
          if (error instanceof Error) {
            return { error: { status: 'FETCH_ERROR', error: error.message }};
          }
          return { error: { status: 'FETCH_ERROR', error: 'An unknown error occurred' }};
        }
      },
    }),
    snapshotRestore: builder.mutation<any, SnapShot & { chunks: any }>({
      queryFn: async (s) => {
        try {
          const actor = await Backend.loadActorByName(s.name as keyof Backend.ActorTypes, s.cid);
          const CHUNK_SIZE = 2031616; // 31 pages of 64 kbytes

          let position = 0;

          while (position < s.chunks.size) {
            const endPosition = Math.min(position + CHUNK_SIZE, s.chunks.size);
            const blobChunk = s.chunks.slice(position, endPosition, s.chunks.type);

            // Convert blobChunk to Uint8Array
            const arrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result as ArrayBuffer);
              reader.onerror = reject;
              reader.readAsArrayBuffer(blobChunk);
            });
            const uint8ArrayChunk = new Uint8Array(arrayBuffer);

            // Write the chunks (assuming `memory_write` is a method on your actor)
            const chunkResponse = await (actor as any).memory_write(s.memoryDataId, position / CHUNK_SIZE, uint8ArrayChunk);
            console.log(chunkResponse);

            position = endPosition; // Update position to the end of the current chunk
          }

          return { data: "Snapshot Restore Successful" }; // Adjust the return value as needed
        } catch (error: any) {
          // Handle error
          return { error: { status: 'CUSTOM_ERROR', error: error.message || 'An unknown error occurred' }};
        }
      },
    }),
    snapshotCreate: builder.mutation<Uint8Array[], SnapShot>({
      queryFn: async (s) => {
        try {
          const actor = await Backend.loadActorByName(s.name as keyof Backend.ActorTypes, s.cid);
          const memorySize = await (actor as any).memory_size(s.memoryDataId);
          const memoryCount = Number(memorySize.Ok);
          const CHUNK_SIZE_IN_PAGES = 31;
          const numberOfChunks = Math.ceil(memoryCount / CHUNK_SIZE_IN_PAGES);
          const allChunks: Uint8Array[] = [];

          for (let i = 0; i < numberOfChunks; i++) {
            const chunk = await (actor as any).memory_read(s.memoryDataId, i);
            allChunks.push(chunk.Ok);
          }

          // Combine all chunks to form a single Blob and trigger download
          const blob = new Blob(allChunks, { type: 'application/octet-stream' });
          const a = document.createElement('a');
          const url = URL.createObjectURL(blob);
          a.href = url;
          a.download = `${s.name}-${moment().format('YYYY-MM-DD-HH:mm:ss')}.bin`;
          a.click();

          // Clean-up
          setTimeout(() => {
              URL.revokeObjectURL(url);
              a.remove();
          }, 0);

          return { data: allChunks };
        } catch (error) {
          if (error instanceof Error) {
            return { error: { status: 'FETCH_ERROR', error: error.message }};
          }
          return { error: { status: 'FETCH_ERROR', error: 'An unknown error occurred' }};
        }
      },
    }),
    snapshotMemory: builder.query<number, SnapShot>({
      queryFn: async (s, queryApi, extraOptions, fetchWithBQ) => {
        try {
          console.log(s);
          const actor = await Backend.loadActorByName(s.name as keyof Backend.ActorTypes, s.cid);
          const memorySize = await (actor as any).memory_size(s.memoryDataId);
          
          console.log(memorySize);
          if (memorySize.Err !== '') {
            return { error: { status: 'CUSTOM_ERROR', error: memorySize.Err } };
          }
          return { data: Number(memorySize.Ok) };
        } catch (error) {
          if (error instanceof Error) {
            return { error: { status: 'FETCH_ERROR', error: error.message }};
          }
          return { error: { status: 'FETCH_ERROR', error: 'An unknown error occurred' }};
        }
      },
    }),
  }),
});

export const { usePlayerCanistersQuery, useMainCanistersQuery, useCdnCanistersQuery, useSnapshotRestoreMutation, useSnapshotCreateMutation, useSnapshotMemoryQuery } = canisterApi;