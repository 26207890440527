import { ActorSubclass, fetchCandid, HttpAgent, Actor, Identity, CallConfig } from '@dfinity/agent';
import { AuthClient, LocalStorage } from "@dfinity/auth-client";

import { idlFactory as CdnBucketIDL } from "../../../declarations/cdn_bucket/cdn_bucket.did";
import { _SERVICE as CdnBucket } from "../../../declarations/cdn_bucket/cdn_bucket.did.t";

import { idlFactory as CdnContainerIDL } from "../../../declarations/cdn_container/cdn_container.did";
import { _SERVICE as CdnContainer } from "../../../declarations/cdn_container/cdn_container.did.t";

import { idlFactory as UserIDL } from "../../../declarations/user/user.did";
import { _SERVICE as User } from "../../../declarations/user/user.did.t";

import { idlFactory as ConfigIDL } from "../../../declarations/config/config.did";
import { _SERVICE as Config } from "../../../declarations/config/config.did.t";

import { idlFactory as TestIDL } from "../../../declarations/test/test.did";

import { _SERVICE as Test } from "../../../declarations/test/test.did.t";

import { idlFactory as WorldIDL } from "../../../declarations/world/world.did";
import { _SERVICE as World } from "../../../declarations/world/world.did.t";

import { idlFactory as WorldBuilderIDL } from "../../../declarations/world_builder/world_builder.did";
import { _SERVICE as WorldBuilder } from "../../../declarations/world_builder/world_builder.did.t";

import { idlFactory as RootIDL } from "../../../declarations/root/root.did";
import { _SERVICE as Root } from "../../../declarations/root/root.did.t";

import { idlFactory as PlayerIDL } from "../../../declarations/player/player.did";
import { _SERVICE as Player } from "../../../declarations/player/player.did.t";


import { idlFactory as idlFactoryIC } from './ic.did';
import {  _SERVICE as ICActor  } from './ic.did.t';

import { LoadRequest } from "../../../declarations/config/config.did.t";
export type Query = LoadRequest;

import { createActor, createAgent } from './actor';
import { IDL } from '@dfinity/candid';
import { Principal } from '@dfinity/principal';
export const createAuthClient = (): Promise<AuthClient> =>
  AuthClient.create({
    // storage: new LocalStorage(),
    idleOptions: {
      // disableIdle: true,
      idleTimeout: 1000 * 60 * 30,
      disableDefaultIdleCallback: true
    }
});

export namespace Backend {

  var authClient: AuthClient;

  export async function setAuthClient(ac: AuthClient) {
    authClient = ac;
  }

  export function getAuthClient() {
    return authClient;
  }


  export async function getCandid () {
    if (process.env.WEBSITE_CANISTER_ID === undefined) {
      return null;
    }
  }

  function ensureAuthClientInitialized(): Promise<void> {
    if (!authClient) {
      return createAuthClient().then(client => {
        authClient = client;
      });
    }
    return Promise.resolve();
  }

  export function createGenericActor(IDL: any, canisterId: string): Promise<ActorSubclass<any>> {
    return ensureAuthClientInitialized().then(() => {
      return createActor({
        IDL,
        canisterId,
        agentOptions: {
          identity: authClient.getIdentity(),
          verifyQuerySignatures: false,
          host: process.env.NODE_ENV !== "production" ? 'http://127.0.0.1:4943' : 'https://icp-api.io'
        },
      });
    });
  }

  export type ActorTypes = {
    Root: Root;
    World: World;
    WorldBuilder: WorldBuilder;
    Config: Config;
    Test: Test;
    User: User;
    cdn_container: CdnContainer;
    cdn_bucket: CdnBucket;
    Player: Player;
  };

  const canisterConfig: any = {
    Root: { IDL: RootIDL, id: process.env.ROOT_CANISTER_ID },
    Config: { IDL: ConfigIDL, id: undefined },
    World: { IDL: WorldIDL, id: undefined },
    WorldBuilder: { IDL: WorldBuilderIDL, id: undefined },
    Test: { IDL: TestIDL, id: process.env.TEST_CANISTER_ID },
    User: { IDL: UserIDL, id: undefined },
    cdn_container: { IDL: CdnContainerIDL, id: process.env.CDN_CONTAINER_CANISTER_ID },
    cdn_bucket: { IDL: CdnBucketIDL, id: undefined },
    Game: { IDL: PlayerIDL, id: undefined },
  };

  export function getCanisterIdByName(name: string, enviroment: string): string {
    const config = canisterConfig[name];
    if (!config) {
      throw new Error(`Unknown canister name: ${name}`);
    }
    if (config.id === undefined) {
      return config.id;
    }
    return config.id[enviroment];
  }

  export async function loadActorByName<K extends keyof ActorTypes>(name: K, canisterId: string): Promise<ActorSubclass<ActorTypes[K]>> {
    const config = canisterConfig[name];
    if (!config) {
      throw new Error(`Actor not found for name: ${name}`);
    }

    const actor = await createGenericActor(config.IDL, canisterId);
    return actor as ActorSubclass<ActorTypes[K]>; // cast here to ensure type safety
  }

  export const getToolsActor = async (identity: Identity): Promise<any> => {
    const toolsCid = process.env.TOOLS_CANISTER_ID;
    // console.log(toolsCid);
    if (toolsCid === undefined) {
      return null;
    }
    // console.log(userCid);
    const agent = await createAgent({
      host: process.env.NODE_ENV !== "production" ? 'http://127.0.0.1:4943' : 'https://icp-api.io',
      identity: identity,
    });
    const Result = IDL.Variant({
      'Ok' : IDL.Null,
      'Err' : IDL.Tuple(IDL.Nat8, IDL.Text),
    });
    const didjs_interface: IDL.InterfaceFactory = ({ IDL }: any) =>
      IDL.Service({
        'create_snapshot' : IDL.Func([IDL.Text], [Result], []),
        'get_canisters' : IDL.Func(
            [],
            [IDL.Opt(IDL.Vec(IDL.Nat8))],
            ['composite_query'],
          ),
        'get_snapshot' : IDL.Func(
            [IDL.Text],
            [IDL.Opt(IDL.Vec(IDL.Nat8))],
            ['composite_query'],
          ),
        'get_status' : IDL.Func(
            [IDL.Text],
            [IDL.Opt(IDL.Vec(IDL.Nat8))],
            ['composite_query'],
          ),
    });
    let actor = await Actor.createActor(didjs_interface, {
      agent,
      canisterId: toolsCid,
    });
    return actor;
  };

  export const getICActor = (params: any): Promise<any> => {
    return createGenericActor(idlFactoryIC, 'aaaaa-aa');
  };
  
}
